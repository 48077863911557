import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Modal } from 'react-bootstrap';
import AppNavbar from './components/AppNavbar';
import Home from "./pages/Home";
import SignUp from "./pages/auth/SignUp";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ResetPassword from "./pages/auth/ResetPassword";
import EditProfile from "./pages/profiles/EditProfile";
import ChangePassword from "./pages/profiles/ChangePassword";
import CategoriesList from "./pages/categories/CategoriesList";
import CreateCategory from "./pages/categories/CreateCategory";
import EditCategory from "./pages/categories/EditCategory";
import IndustriesList from "./pages/industries/IndustriesList";
import CreateIndustry from "./pages/industries/CreateIndustry";
import EditIndustry from "./pages/industries/EditIndustry";
import ApprovedCompaniesList from "./pages/approved_companies/ApprovedCompaniesList";
import CreateApprovedCompany from "./pages/approved_companies/CreateApprovedCompany";
import EditApprovedCompany from "./pages/approved_companies/EditApprovedCompany";
import UsersList from "./pages/users/UsersList";
import EditUser from "./pages/users/EditUser";
import CreateUser from "./pages/users/CreateUser";
import { decodeJWT } from "./utils/helper";
import WarehousesList from "./pages/warehouses/WarehousesList";
import CreateWarehouse from "./pages/warehouses/CreateWarehouse";
import EditWarehouse from "./pages/warehouses/EditWarehouse";
import ProductProposalsList from "./pages/product_proposals/ProductProposalsList";
import CreateProductProposal from "./pages/product_proposals/CreateProductProposal";
import MyCategories from "./pages/profiles/MyCategories";
import MyApprovedCompanies from "./pages/profiles/MyApprovedCompanies";
import ProductsList from "./pages/products/ProductsList";
import CreateProduct from "./pages/products/CreateProduct";
import EditProduct from "./pages/products/EditProduct";
import SellerProductsList from "./pages/seller_products/SellerProductsList";
import CreateSellerProduct from "./pages/seller_products/CreateSellerProduct";
import PurchaseRequestsList from "./pages/purchase_requests/PurchaseRequestsList";
import CreatePurchaseRequest from "./pages/purchase_requests/CreatePurchaseRequest";
import RequestForQuotation from "./pages/quotations/RequestForQuotation";
import QuotationsList from "./pages/quotations/QuotationsList";
import SendQuotation from "./pages/quotations/SendQuotation";
import QuotationComparison from "./pages/quotations/QuotationComparison";
import PurchaseOrdersList from "./pages/purchase_orders/PurchaseOrdersList";
import CreatePurchaseOrder from "./pages/purchase_orders/CreatePurchaseOrder";
import UserDocumentsList from "./pages/user_documents/UserDocumentsList";
import CreateUserDocument from "./pages/user_documents/CreateUserDocument";
import ViewProduct from "./pages/products/ViewProduct";
import SignInPage from "./pages/auth/SignInPage";
import SignIn from "./components/SignIn";
import { useState } from "react";
import AboutUs from "./pages/AboutUs";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsConditions from "./pages/TermsConditions";
import ContactUs from "./pages/ContactUs";
import VerifyEmail from "./pages/auth/VerifyEmail";
import ViewPurchaseOrder from "./pages/purchase_orders/ViewPurchaseOrder";
import SignUpPage from "./pages/auth/SignUpPage";
import ForgotPasswordPage from "./pages/auth/ForgotPasswordPage";
function App() {
  const jwtDecode = decodeJWT();
  const [authModalShow, setAuthModalShow] = useState(false);
  const [currentAuthModalForm, setCurrentAuthModalForm] = useState("SignIn");
  const handleCurrentAuthModalForm = (value) => setCurrentAuthModalForm(value);
  const handleAuthModalClose = (value) => setAuthModalShow(value);
  return (
    <BrowserRouter>
      <AppNavbar onHandleAuthModalClose={handleAuthModalClose} onHandleCurrentAuthModalForm={handleCurrentAuthModalForm} />
      <Modal show={authModalShow} backdrop={'static'} onHide={() => handleAuthModalClose(false)} >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body className="p-0">
          {
            (currentAuthModalForm === 'SignIn') ? <SignIn onHandleCurrentAuthModalForm={handleCurrentAuthModalForm} /> : null
          }
          {
            (currentAuthModalForm === 'SignUp') ? <SignUp onHandleCurrentAuthModalForm={handleCurrentAuthModalForm} /> : null
          }
          {
            (currentAuthModalForm === 'ForgotPassword') ? <ForgotPassword onHandleCurrentAuthModalForm={handleCurrentAuthModalForm} /> : null
          }

        </Modal.Body>
      </Modal>
      <Routes>
        <Route path="/" exact element={<Home onHandleAuthModalClose={handleAuthModalClose} onHandleCurrentAuthModalForm={handleCurrentAuthModalForm} />} />
        <Route path="/about_us" exact element={<AboutUs />} />
        <Route path="/privacy_policy" exact element={<PrivacyPolicy />} />
        <Route path="/terms_conditions" exact element={<TermsConditions />} />
        <Route path="/contact_us" exact element={<ContactUs />} />
        <Route path="/auth/signin" exact element={<SignInPage />} />
        <Route path="/auth/signup" exact element={<SignUpPage />} />
        <Route path="/password/forgot" exact element={<ForgotPasswordPage />} />
        <Route path="/password/reset/:token" exact element={<ResetPassword />} />
        <Route path="/email/verify/:token" exact element={<VerifyEmail />} />
        {
          jwtDecode ?
            <>
              <Route path="/profile" exact element={<EditProfile />} />
              <Route path="/profile/password/change" exact element={<ChangePassword />} />
            </> : null
        }

        {
          jwtDecode && jwtDecode.user_role === "A" ?
            <>
              <Route path="/admin/categories" exact element={<CategoriesList />} />
              <Route path="/admin/categories/create" exact element={<CreateCategory />} />
              <Route path="/admin/categories/:id/edit" exact element={<EditCategory />} />
              <Route path="/admin/industries" exact element={<IndustriesList />} />
              <Route path="/admin/industries/create" exact element={<CreateIndustry />} />
              <Route path="/admin/industries/:id/edit" exact element={<EditIndustry />} />
              <Route path="/admin/approved_companies" exact element={<ApprovedCompaniesList />} />
              <Route path="/admin/approved_companies/create" exact element={<CreateApprovedCompany />} />
              <Route path="/admin/approved_companies/:id/edit" exact element={<EditApprovedCompany />} />
              <Route path="/admin/users" exact element={<UsersList />} />
              <Route path="/admin/users/create" exact element={<CreateUser />} />
              <Route path="/admin/users/:id/edit" exact element={<EditUser />} />
              <Route path="/admin/documents" exact element={<UserDocumentsList />} />
              <Route path="/admin/product_proposals" exact element={<ProductProposalsList />} />
              <Route path="/admin/products" exact element={<ProductsList />} />
              <Route path="/admin/products/create" exact element={<CreateProduct />} />
              <Route path="/admin/products/:id/edit" exact element={<EditProduct />} />
              <Route path="/admin/products/:id" exact element={<ViewProduct />} />
            </> : null
        }
        {
          jwtDecode && jwtDecode.user_role === "S" ?
            <>
              <Route path="/seller/warehouses" exact element={<WarehousesList />} />
              <Route path="/seller/warehouses/create" exact element={<CreateWarehouse />} />
              <Route path="/seller/warehouses/:id/edit" exact element={<EditWarehouse />} />
              <Route path="/seller/documents" exact element={<UserDocumentsList />} />
              <Route path="/seller/documents/create" exact element={<CreateUserDocument />} />
              <Route path="/seller/product_proposals" exact element={<ProductProposalsList />} />
              <Route path="/seller/product_proposals/create" exact element={<CreateProductProposal />} />
              <Route path="/seller/categories" exact element={<MyCategories />} />
              <Route path="/seller/approved_companies" exact element={<MyApprovedCompanies />} />
              <Route path="/seller/products" exact element={<SellerProductsList />} />
              <Route path="/seller/products/import" exact element={<CreateSellerProduct />} />
              <Route path="/seller/quotations" exact element={<QuotationsList />} />
              <Route path="/seller/quotations/:id/send" exact element={<SendQuotation />} />
              <Route path="/purchase_orders" exact element={<PurchaseOrdersList />} />
              <Route path="/purchase_orders/:id" exact element={<ViewPurchaseOrder />} />
            </> : null
        }
        {
          jwtDecode && jwtDecode.user_role === "B" ?
            <>
              <Route path="/purchase_requests" exact element={<PurchaseRequestsList />} />
              <Route path="/purchase_requests/create" exact element={<CreatePurchaseRequest />} />
              <Route path="/purchase_requests/:id/rfq" exact element={<RequestForQuotation />} />
              <Route path="/purchase_requests/:id/compare" exact element={<QuotationComparison />} />
              <Route path="/purchase_orders" exact element={<PurchaseOrdersList />} />
              <Route path="/purchase_orders/:id" exact element={<ViewPurchaseOrder />} />
              <Route path="/purchase_orders/create" exact element={<CreatePurchaseOrder />} />
            </> : null
        }
        <Route path="*" exact element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
}
export default App;