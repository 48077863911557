import { useEffect, useState } from "react";
import Select from "react-select";
import { fetchIndustryLists } from "../../services/industries";

function IndustrySelect(props) {
  const { inputName, onChangeIndustryOption, selectedValues, isMultiple } =
    props;
  const [industryOptions, setIndustryOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  useEffect(() => {
    const _fetchIndustryLists = async () => {
      await fetchIndustryLists()
        .then((response) => {
          const { data } = response;
          if (data.data.industries) {
            const _options = [];
            const _selectedOptions = [];
            data.data.industries.forEach((v) => {
              _options.push({
                value: v["id"],
                label: v["name"],
              });
              if (selectedValues.includes(v["id"])) {
                _selectedOptions.push({
                  value: v["id"],
                  label: v["name"],
                });
              }
            });
            setIndustryOptions(_options);
            if (_selectedOptions.length) {
              setSelectedOptions(_selectedOptions);
            }
          }
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    };
    _fetchIndustryLists();
  }, []);
  return (
    <Select
      isMulti={isMultiple}
      name={inputName}
      options={industryOptions}
      value={selectedOptions}
      onChange={(e) => {
        if (selectedOptions) {
          setSelectedOptions(e);
        }
        if (onChangeIndustryOption) {
          const _selectedOptions = [];
          e.forEach((v) => {
            _selectedOptions.push(v["value"]);
          });
          onChangeIndustryOption(_selectedOptions);
        }
      }}
    />
  );
}

IndustrySelect.defaultProps = {
  inputName: "industry_id",
  onChangeIndustryOption: null,
  selectedValues: [],
  isMultiple: false,
};

export default IndustrySelect;
