import { Container, Button, Row, Col } from "react-bootstrap";
import AppFooter from "../components/AppFooter";
import { useRef, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
export default function AboutUs() {
  const [hasScrolled, setHasScrolled] = useState(false);
  const { hash } = useLocation();
  const whySection = useRef(null);
  useEffect(() => {
    if (whySection.current && hash === "#why" && !hasScrolled) {
      whySection.current.scrollIntoView({
        behavior: "smooth",
      });
      setHasScrolled(true);
    }

    return () => {
      setHasScrolled(false);
    };
  }, [whySection, hash]);
  return (
    <>
      <div
        style={{
          background: "rgb(54, 147, 61)",
          color: "white",
        }}
      >
        <Container className="py-3">
          <h2>
            <strong>About Us</strong>
          </h2>
        </Container>
      </div>
      <div className="container my-5 mt-3">
        <h3>Welcome to Eprocureglobal - Your Global Procurement Partner!</h3>
        <p>
          Eprocureglobal is global procurement and business information platform
          that helps suppliers, and service providers, to find business
          opportunities globally.
        </p>
        <p>
          Contractors benefit from the speed efficiency and transparency of the
          procurement process. With us users can reduce the time and money spent
          searching for B2B solutions.
        </p>
        <p>
          Eprocureglobal opens doors to abundant opportunities. With its
          intuitive interface and vast procurement database, buyers effortlessly
          find materials or request purchases tailored to their business needs.
          For suppliers and manufacturers, it is a gateway to global sales.
        </p>
        <p>
          Join Eprocureglobal today and experience the power of efficient
          procurement solutions. Together, let's unlock new possibilities and
          drive business success!
        </p>
        <h3 id="why" ref={whySection}>
          <strong>Why use e-ProcureGlobal</strong>
        </h3>
        <p>
          Eprocureglobal is designed to maximize the use of technology in the
          procurement processes. We publish and sends email to users each time
          procurement request is raised by the Buyer and business opportunity
          exists; this includes personalized notice of opportunities, together
          with links to detailed information about each request.
        </p>
        <p>
          <strong>Time Efficiency:</strong> The website allows buyers to issue
          letter of intent with just a few clicks, significantly reducing the
          time and effort required for procurement.
        </p>
        <p>
          <strong>Cost Savings:</strong> By providing access to a marketplace
          with various vendors and suppliers, buyers can easily compare prices
          and select the best market rate, leading to cost savings.
        </p>
        <p>
          <strong>Simplified Procurement:</strong> The platform streamlines the
          entire procurement process. Buyers can quickly find the industrial
          materials they need and issue purchase orders, reducing the need for a
          large procurement team.
        </p>
        <p>
          <strong>Diverse Selection:</strong>{" "}
          <a href="https://eprocureglobal.com">eprocureglobal.com</a> offers a
          wide range of industrial materials from multiple vendors, ensuring
          buyers have a variety of options to choose from in one centralized
          location.
        </p>
        <p>
          <strong>Quick Request Handling:</strong> If a specific material isn't
          available, buyers can send a material request with a single click,
          enabling vendors to respond quickly and helping buyers avoid lengthy
          delays.
        </p>
        <p>
          <strong>Reduced Administrative Overhead:</strong> The simplified
          process and reduced need for a large team lower administrative cost,
          allowing businesses to reallocate resources more effectively.
        </p>
        <p>
          <strong>Streamlined Communication:</strong> The platform facilitates
          direct communication between buyers and suppliers, improving
          transparency and making it easier to manage procurement processes.
        </p>
        <p>
          <strong>Improved Vendor Relationships:</strong> By providing a
          marketplace that connects buyers with a wide range of suppliers,
          <a href="https://eprocureglobal.com">eprocureglobal.com</a> helps
          build relationships and fosters a competitive environment that can
          lead to better deals and partnerships.
        </p>
        <p>
          <strong>Data and Analytics:</strong> The website might offer data and
          analytics tools, enabling buyers to track purchasing trends, spending,
          and supplier performance for better decision-making.
        </p>
        <p>
          <strong>User-Friendly Interface:</strong> The platform is designed to
          be user-friendly, making it accessible for both experienced
          procurement professionals and those new to the process.
        </p>
      </div>
      <AppFooter />
    </>
  );
}
