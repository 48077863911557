import { _APIHandler } from "../utils/helper";

let baseUrl = "product_proposals";
export const fetchProductProposals = (queryParams) => _APIHandler.get(`${baseUrl}`, { params: queryParams });
export const fetchProductProposal = (id) => _APIHandler.get(`${baseUrl}/${id}`);
export const createProductProposal = (data) => _APIHandler.post(`${baseUrl}`, data, {
    headers: {
        'Content-Type': 'multipart/form-data',
    },
});
export const updateProductProposal = (id, data) => _APIHandler.put(`${baseUrl}/${id}`, data);
export const updateProductProposalStatus = (id, data) => _APIHandler.put(`${baseUrl}/${id}/update_status`, data);
export const deleteProductProposal = (id) => _APIHandler.delete(`${baseUrl}/${id}`);