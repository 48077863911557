import { _APIHandler } from "../utils/helper";

let baseUrl = "seller/products";
export const fetchSellerProducts = (queryParams) => _APIHandler.get(`${baseUrl}`, { params: queryParams });
export const fetchSellerProductListsByCountries = (data) => _APIHandler.post(`${baseUrl}/productListsByCountries`, data);
export const fetchSellerProductByProductId = (id) => _APIHandler.get(`${baseUrl}/${id}/productId`);
export const fetchSellerProduct = (id) => _APIHandler.get(`${baseUrl}/${id}`);
export const createSellerProduct = (data) => _APIHandler.post(`${baseUrl}`, data,);
export const deleteSellerProduct = (id) => _APIHandler.delete(`${baseUrl}/${id}`);
export const createSellerProductMake = (data) => _APIHandler.post(`${baseUrl}/makes`, data,);
export const fetchSellerProductMake = (id) => _APIHandler.get(`${baseUrl}/${id}/makes`);
export const updateSellerProductMake = (id, data) => _APIHandler.put(`${baseUrl}/${id}/makes`, data);
export const deleteSellerProductMake = (id) => _APIHandler.delete(`${baseUrl}/${id}/makes`);