import { Container, Row, Col } from "react-bootstrap";
import SignIn from "../../components/SignIn";
function SignInPage() {
  return (
    <Container className="my-5">
      <Row>
        <Col md="4"></Col>
        <Col md="4">
          <SignIn />
        </Col>
        <Col md="4"></Col>
      </Row>
    </Container>
  );
}

export default SignInPage;
