import { _APIHandler } from "../utils/helper";

let baseUrl = "approved_companies";
export const fetchApprovedCompanies = (queryParams) => _APIHandler.get(`${baseUrl}`, { params: queryParams });
export const fetchUserApprovedCompanies = () => _APIHandler.get(`${baseUrl}/users`);
export const fetchApprovedCompanyLists = () => _APIHandler.get(`${baseUrl}/lists`);
export const fetchApprovedCompany = (id) => _APIHandler.get(`${baseUrl}/${id}`);
export const createApprovedCompany = (data) => _APIHandler.post(`${baseUrl}`, data,);
export const updateApprovedCompany = (id, data) => _APIHandler.put(`${baseUrl}/${id}`, data);
export const updateUserApprovedCompany = (data) => _APIHandler.put(`${baseUrl}/users`, data);
export const deleteApprovedCompany = (id) => _APIHandler.delete(`${baseUrl}/${id}`);