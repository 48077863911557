import { Container, Button, Row, Col, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AppFooter from "../components/AppFooter";
import { FaPlayCircle } from "react-icons/fa";
export default function Home(props) {
  const { onHandleAuthModalClose, onHandleCurrentAuthModalForm } = props;
  const navigate = useNavigate();
  return (
    <>
      <div
        style={{
          background: "rgb(54, 147, 61)",
        }}
        className="py-5"
      >
        <Container>
          <h1
            className="py-5 mt-5"
            style={{
              fontSize: "36px",
              fontWeight: "bold",
              color: "white",
            }}
          >
            Streamlining B2B procurement to unlock unprecedented efficiency,
            delivering the fastest and most effective solutions for all your
            business procurement needs.
          </h1>
          <Button
            variant="warning"
            size="lg"
            onClick={() => {
              onHandleAuthModalClose(true);
              onHandleCurrentAuthModalForm("SignUp");
            }}
          >
            Join Us
          </Button>
        </Container>
      </div>
      <Container className="my-5">
        <p
          className="mt-5"
          style={{
            fontWeight: "bold",
            textAlign: "center",
            fontSize: "42px",
          }}
        >
          How it Works
        </p>
        <Row>
          <Col md="3" style={{ textAlign: "center" }}>
            <Card style={{ border: "none" }}>
              <Card.Body>
                <Card.Title>Buyer</Card.Title>
                <FaPlayCircle size={"42px"} />
                <Card.Text>
                  <ul
                    style={{
                      padding: "0px",
                      listStyle: "none",
                      textAlign: "justify",
                    }}
                  >
                    <li>
                      Explore a Diverse Array of Global and Local Suppliers
                      Offering Competitive-Priced Products.
                    </li>
                    <li className="mt-2">
                      Quickly select your desired materials within minutes and
                      compare costs from various suppliers.
                    </li>
                    <li className="mt-2">
                      Choose the preferred supplier and send a notification for
                      their confirmation.
                    </li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md="3" style={{ textAlign: "center" }}>
            <Card style={{ border: "none" }}>
              <Card.Body>
                <Card.Title>Supplier</Card.Title>
                <FaPlayCircle size={"42px"} />
                <Card.Text>
                  <ul
                    style={{
                      padding: "0px",
                      listStyle: "none",
                      textAlign: "justify",
                    }}
                  >
                    <li>
                      Seamlessly upload their stock into our database and
                      receive timely notifications.
                    </li>
                    <li className="mt-2">
                      Receive email alerts upon publication of procurement
                      requests on our platform.
                    </li>
                    <li className="mt-2">
                      Promptly accept requests, and seamlessly forward them to
                      the system for sellers to issue the Letter of intent
                    </li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md="3" style={{ textAlign: "center" }}>
            <Card style={{ border: "none" }}>
              <Card.Body>
                <Card.Title>Unified Supplier Access</Card.Title>
                <FaPlayCircle size={"42px"} />
                <Card.Text>
                  <ul
                    style={{
                      padding: "0px",
                      listStyle: "none",
                      textAlign: "justify",
                    }}
                  >
                    <li>
                      A seamless procurement process from multiple Suppliers
                      thru our marketplace. If you cannot find your desired
                      product from Marketplace, you can raise a Request for
                      Quotation to local and global suppliers and expedite your
                      procurement process using the same platform.
                    </li>
                    <li className="mt-2">
                      Generate the LOI from your system with mutually agreed
                      terms and conditions.
                    </li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md="3" style={{ textAlign: "center" }}>
            <Card style={{ border: "none" }}>
              <Card.Body>
                <Card.Title>Subscribe and Register Now!</Card.Title>
                <FaPlayCircle size={"42px"} />
                <Card.Text>
                  <ul
                    style={{
                      padding: "0px",
                      listStyle: "none",
                      textAlign: "justify",
                    }}
                  >
                    <li>
                      The registration process is incredibly easy.{" "}
                      <a
                        href="javascript:void(0);"
                        onClick={() => navigate("auth/signup")}
                      >
                        Register now
                      </a>{" "}
                      and unlock the opportunity to grow your business with a
                      seamless procurement experience. If you need further
                      assistance, feel free to contact us via WhatsApp or email.
                    </li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container>
        <p
          style={{
            fontSize: "24px",
            textAlign: "justify",
          }}
        >
          Eprocureglobal opens doors to abundant opportunities. With its
          intuitive interface and vast procurement database, buyers effortlessly
          find materials or request purchases tailored to their business needs.
          For suppliers and manufacturers, it's a gateway to global sales.
        </p>
      </Container>
      <Container
        className="p-2 my-5"
        style={{
          fontWeight: "600",
          textAlign: "center",
          background: "#36933d",
          borderRadius: "10px",
        }}
      >
        <p
          style={{
            fontSize: "24px",
            fontWeight: "bold",
            color: "white",
          }}
          className="m-0"
        >
          The best investment a business can make is in the pursuit of new
          business through technology.
        </p>
      </Container>
      <Container className="my-5">
        <Row>
          <Col md="4">
            <div style={{ textAlign: "justify" }}>
              <strong>About US</strong>
              <p>
                Eprocureglobal is global procurement and business information
                platform that helps suppliers, and service providers, to find
                business opportunities globally. Contractors benefit from the
                speed efficiency and transparency of the procurement process.
                With us users can reduce the time and money spent searching for
                B2B solutions.
                <br />
                <Button
                  className="btn btn-sm btn-success"
                  onClick={() => (window.location.href = "about_us")}
                >
                  More...
                </Button>
              </p>
            </div>
          </Col>
          <Col md="4">
            <div style={{ textAlign: "justify" }}>
              <strong>Why use e-ProcureGlobal</strong>
              <p>
                Eprocureglobal is designed to maximize the use of technology in
                the procurement processes. We publish and sends email to users
                each time procurement request is raised by the Buyer and
                business opportunity exists; this includes personalized notice
                of opportunities, together with links to detailed information
                about each request.
                <br />
                <Button
                  className="btn btn-sm btn-success"
                  onClick={() => navigate("about_us/#why")}
                >
                  More...
                </Button>
              </p>
            </div>
          </Col>
          <Col md="4">
            <div style={{ textAlign: "justify" }}>
              <strong>Privacy Policy</strong>
              <p>
                This Privacy Policy (the “Policy”) applies to services offered
                by eproureglobal, its brands under the domain eprocureglobal.com
                By visiting this website you agree to be bound by the terms and
                conditions of this Policy. If you do not agree, please to not
                use or access our Site.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <AppFooter />
    </>
  );
}
