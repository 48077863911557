import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Card, Form, Button, Table, Row, Col } from "react-bootstrap";
import { fetchQuotation, sendQuotation } from "../../services/quotations";
import AppAlert from "../../components/AppAlert";
import { _swal } from "../../utils/helper";
function SendQuotation() {
  const { id: quotationId } = useParams();
  const navigate = useNavigate();
  const [quotationData, setQuotationData] = useState([]);
  const [quotationItemData, setQuotationItemData] = useState([]);
  const [computedPrice, setComputedPrice] = useState({
    netTotal: 0,
    items: {},
  });
  const [errorMessages, setErrorMessages] = useState([]);
  const computePriceCalc = (updatedItemList) => {
    const _computeItems = {};
    let _netTotal = 0;
    updatedItemList.forEach((v) => {
      _computeItems[v["id"]] = {
        taxAmount: v["quantity"] * v["unit_price"] * (v["tax_rate"] / 100),
        totalAmount:
          v["quantity"] * v["unit_price"] +
          v["quantity"] * v["unit_price"] * (v["tax_rate"] / 100),
      };
      _netTotal +=
        v["quantity"] * v["unit_price"] +
        v["quantity"] * v["unit_price"] * (v["tax_rate"] / 100);
    });
    const _computePrice = {
      ...computedPrice,
      netTotal: _netTotal,
      items: _computeItems,
    };
    setComputedPrice(_computePrice);
  };
  const updateLineItem = (target, key, value) => {
    const _itemsList = [...quotationItemData];
    const updatedItemList = _itemsList.map((v) => {
      if (target === v.id) {
        v[key] = value;
      }
      return v;
    });
    computePriceCalc(updatedItemList);
    setQuotationItemData(updatedItemList);
  };

  const _fetchQuotation = async (quotationId) => {
    await fetchQuotation(quotationId)
      .then((response) => {
        const { data } = response;
        setQuotationData(data.data.quotation);
        const _QuotationItems = [];
        data.data.quotation.quotation_items.forEach((v) => {
          _QuotationItems.push({ ...v });
        });
        setQuotationItemData(_QuotationItems);
        const _computePriceItems = {};
        data.data.quotation.quotation_items.forEach((v) => {
          _computePriceItems[v["id"]] = {
            taxAmount: 0,
            totalAmount: 0,
          };
        });
        computePriceCalc(data.data.quotation.quotation_items);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const formData = {
      quotation_id: e.target.quotation_id.value,
      items: quotationItemData,
    };
    sendQuotation(formData)
      .then((response) => {
        if (response.data.status) {
          _swal(response.data.status, response.data.message);
          navigate("/seller/quotations");
        }
      })
      .catch((err) => {
        if (err.response.data.errors) {
          const _errorMessages = [];
          Object.entries(err.response.data.errors).forEach((e) => {
            _errorMessages.push(e[1]);
          });
          setErrorMessages([..._errorMessages]);
        }
        _swal(err.response.data.status, err.response.data.message);
      });
  };
  useEffect(() => {
    _fetchQuotation(quotationId);
  }, []);
  return (
    <Container className="my-2">
      <Card>
        <Card.Header as="h5">Quotation - RFQ #{quotationId}</Card.Header>
        <Card.Body>
          <Form onSubmit={onSubmit}>
            {errorMessages.length ? <AppAlert errors={errorMessages} /> : null}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Quotation ID
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="number"
                  name="quotation_id"
                  value={quotationData.id}
                  readOnly
                />
              </Col>
            </Form.Group>
            <h5>Quotation Items</h5>
            <Table>
              <thead>
                <tr>
                  <th width={"25%"}>Product</th>
                  <th width={"10%"}>Avail. Qty</th>
                  <th width={"10%"}>Unit Price</th>
                  <th width={"10%"}>Tax Rate</th>
                  <th width={"10%"}>Tax Amount</th>
                  <th width={"10%"}>Delivery Date</th>
                  <th width={"10%"}>Total Cost</th>
                </tr>
              </thead>
              <tbody>
                {quotationItemData &&
                  quotationItemData.map((q) => {
                    return (
                      <tr>
                        <td>{q.product_description}</td>
                        <td>
                          <Form.Control
                            type="number"
                            name={`quantity[${q["id"]}]`}
                            onChange={(e) => {
                              updateLineItem(
                                q["id"],
                                "quantity",
                                e.target.value
                              );
                            }}
                          />
                        </td>
                        <td>
                          <Form.Control
                            type="number"
                            name={`unit_price[${q["id"]}]`}
                            onChange={(e) => {
                              updateLineItem(
                                q["id"],
                                "unit_price",
                                e.target.value
                              );
                            }}
                          />
                        </td>
                        <td>
                          <Form.Control
                            type="number"
                            name={`tax_rate[${q["id"]}]`}
                            onChange={(e) => {
                              updateLineItem(
                                q["id"],
                                "tax_rate",
                                e.target.value
                              );
                            }}
                          />
                        </td>
                        <td>
                          <Form.Control
                            type="number"
                            name={`tax_amount[${q["id"]}]`}
                            value={computedPrice["items"][q["id"]]["taxAmount"]}
                            readOnly
                          />
                        </td>
                        <td>
                          <Form.Control
                            type="date"
                            name={`delivery_date[${q["id"]}]`}
                            onChange={(e) => {
                              updateLineItem(
                                q["id"],
                                "delivery_date",
                                e.target.value
                              );
                            }}
                          />
                        </td>
                        <td>
                          <Form.Control
                            type="number"
                            style={{ textAlign: "right" }}
                            name={`total_amount[${q["id"]}]`}
                            value={
                              computedPrice["items"][q["id"]]["totalAmount"]
                            }
                            readOnly
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={"6"} style={{ textAlign: "right" }}>
                    <b>Total</b>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {computedPrice.netTotal}
                  </td>
                </tr>
              </tfoot>
            </Table>
            <Button type="submit" variant="primary">
              Submit
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default SendQuotation;
