import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Container,
  Card,
  Form,
  Button,
  Table,
  Row,
  Col,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import {
  fetchProducts,
  cloneProduct,
  deleteProduct,
} from "../../services/products";
import { FaFolderOpen, FaClone, FaPencil, FaTrash } from "react-icons/fa6";
import { _swal } from "../../utils/helper";
function ProductsList() {
  const navigate = useNavigate();
  const [productsData, setProductsData] = useState({});
  const searchSchema = {
    title: null,
  };
  const [ProductSearchValues, setProductSearchValues] = useState(searchSchema);
  const _fetchProducts = async (queryParams = {}) => {
    await fetchProducts(queryParams)
      .then((response) => {
        const { data } = response;
        setProductsData(data.data.products);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };
  const _cloneProduct = async (id) => {
    const _confirm = window.confirm("Are you sure to clone?");
    if (_confirm) {
      await cloneProduct(id)
        .then((response) => {
          _swal(response.data.status, response.data.message);
          navigate(
            "/admin/products/" + response.data.data.product_id + "/edit"
          );
        })
        .catch((err) => {
          _swal(err.response.data.status, err.response.data.message);
        });
    }
  };
  const _deleteProduct = async (id) => {
    const _confirm = window.confirm("Are you sure to delete?");
    if (_confirm) {
      await deleteProduct(id)
        .then((response) => {
          const { data } = response;
          _swal(response.data.status, response.data.message);
          _fetchProducts();
        })
        .catch((err) => {
          _swal(err.response.data.status, err.response.data.message);
          console.log(err.response.data);
        });
    }
  };
  const resetSearch = () => {
    _fetchProducts({
      page: 1,
      per_page: 100,
      sort_column: "id",
      sort_order: "DESC",
    });
  };
  const filterProduct = (
    page = null,
    sort_column = null,
    sort_order = null
  ) => {
    const filteredKeys = {};
    Object.entries(ProductSearchValues).filter((v) => {
      if (v[1] !== null) {
        filteredKeys[v[0]] = v[1];
      }
    });
    _fetchProducts({
      ...filteredKeys,
      page: page ? page : 1,
      per_page: 100,
      sort_column: sort_column ? sort_column : "id",
      sort_order: sort_order ? sort_order : "DESC",
    });
  };
  useEffect(() => {
    _fetchProducts({
      page: 1,
      per_page: 100,
      sort_column: "id",
      sort_order: "DESC",
    });
  }, []);
  return (
    <Container className="my-2">
      <Card>
        <Card.Header as="h5">
          Products{" "}
          <Button
            variant="primary"
            as={Link}
            to={"/admin/products/create"}
            size="sm"
          >
            New
          </Button>
        </Card.Header>
        <Card.Body>
          <Form>
            <Row>
              <Col md="3">
                <Form.Group className="mb-3">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    placeholder="Title"
                    value={
                      ProductSearchValues.title !== null
                        ? ProductSearchValues.title
                        : ""
                    }
                    onChange={(e) =>
                      setProductSearchValues({
                        ...ProductSearchValues,
                        title: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Button
              onClick={() => filterProduct()}
              variant="primary"
              className="mr-2"
            >
              Filter
            </Button>
            <Button
              onClick={(e) => {
                setProductSearchValues({ ...searchSchema });
                resetSearch();
              }}
              variant="default"
            >
              Reset
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <Table bordered width={"100%"} className="mt-2">
        <thead>
          <tr>
            <th width="15%">Title</th>
            <th width="40%">Description</th>
            <th width="15%" className="text-center">
              Total Variants
            </th>
            <th width="15%" className="text-center">
              Status
            </th>
            <th className="text-center" width="10%">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {productsData.length ? (
            productsData.map((e) => {
              return (
                <tr>
                  <td>{e.title}</td>
                  <td>{e.description}</td>
                  <td className="text-center">{e.total_variants_count}</td>
                  <td className="text-center">{e.status}</td>
                  <td className="text-center">
                    <DropdownButton title="Action" variant="default">
                      <Dropdown.Item as={Link} to={"/admin/products/" + e.id}>
                        <FaFolderOpen /> View Product
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => _cloneProduct(e.id)}>
                        <FaClone /> Clone Product
                      </Dropdown.Item>
                      {e.status === "Draft" ? (
                        <>
                          <Dropdown.Item
                            as={Link}
                            to={"/admin/products/" + e.id + "/edit"}
                          >
                            <FaPencil /> Edit Product
                          </Dropdown.Item>
                          <Dropdown.Divider />
                          <Dropdown.Item onClick={() => _deleteProduct(e.id)}>
                            <FaTrash /> Delete Product
                          </Dropdown.Item>
                        </>
                      ) : null}
                    </DropdownButton>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={"3"}>Products not found</td>
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  );
}

export default ProductsList;
