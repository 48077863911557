import { Container, Button, Row, Col } from "react-bootstrap";
import { FaSquareTwitter, FaYoutube, FaInstagram } from "react-icons/fa6";
function AppFooter() {
  return (
    <Container className="my-5">
      <Row>
        <Col>© 2024 eProcureGlobal. All rights reserved</Col>
        <Col style={{ textAlign: "right" }}>
          <Button variant="link">
            <FaSquareTwitter size={"48px"} />
          </Button>
          <Button variant="link">
            <FaYoutube size={"48px"} />
          </Button>
          <Button variant="link">
            <FaInstagram size={"48px"} />
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default AppFooter;
