import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Container,
  Card,
  Form,
  Button,
  Row,
  Col,
  Table,
} from "react-bootstrap";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { FaEllipsisVertical } from "react-icons/fa6";
import { fetchCategoryLists } from "../../services/categories";
import { fetchIndustryLists } from "../../services/industries";
import { fetchProduct, updateProduct } from "../../services/products";
import { _swal, _uuid, cartesianProduct } from "../../utils/helper";
import AppAlert from "../../components/AppAlert";
import CategorySelect from "../../components/Input/CategorySelect";
import IndustrySelect from "../../components/Input/IndustrySelect";
function EditProduct() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isVariantFinalized, setIsVariantFinalized] = useState(false);
  const [categoriesListData, setCategoryListData] = useState([]);
  const [industriesListData, setIndustryListData] = useState([]);
  const [productData, setProductData] = useState({});
  const [productAttributes, setProductAttributes] = useState([]);
  const [productVariants, setProductVariants] = useState([]);
  const [filteredProductVariants, setFilteredProductVariants] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState({});
  const [draggingItem, setDraggingItem] = useState(null);
  const [errorMessages, setErrorMessages] = useState([]);
  const _fetchCategoryLists = async () => {
    await fetchCategoryLists()
      .then((response) => {
        const { data } = response;
        setCategoryListData(data.data.categories);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };
  const _fetchIndustryLists = async () => {
    await fetchIndustryLists()
      .then((response) => {
        const { data } = response;
        setIndustryListData(data.data.industries);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };
  const _fetchProduct = async (id) => {
    await fetchProduct(id)
      .then((response) => {
        const { data } = response;
        setProductData(data.data.product);
        setProductAttributes([
          ...JSON.parse(data.data.product.product_options),
        ]);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };
  const addAttribute = () => {
    const _productAttributes = [...productAttributes];
    _productAttributes.push({
      id: _uuid(),
      name: "",
      options: [],
    });
    setProductAttributes(_productAttributes);
  };
  const updateAttributeName = (id, value) => {
    const _productAttributes = [...productAttributes];
    const _updated = _productAttributes.map((v) => {
      if (v["id"] == id) {
        return { ...v, name: value };
      }
      return v;
    });
    setProductAttributes(_updated);
  };
  const updateAttributeOptions = (id, options) => {
    const _productAttributes = [...productAttributes];
    const _updated = _productAttributes.map((v) => {
      if (v["id"] == id) {
        return { ...v, options };
      }
      return v;
    });
    setProductAttributes(_updated);
  };
  const deleteAttribute = (id) => {
    const _productAttributes = [...productAttributes];
    const _updated = _productAttributes.filter((v) => v["id"] != id);
    setProductAttributes(_updated);
  };
  const handleDragStart = (e, item) => {
    console.log("source", item);
    setDraggingItem(item);
    e.dataTransfer.setData("text/plain", "");
  };
  const handleDragEnd = () => {
    setDraggingItem(null);
  };
  const handleDragOver = (e) => {
    e.preventDefault();
  };
  const handleDrop = (e, targetItem) => {
    const _productAttributes = [...productAttributes];
    const currentVariantOption = _productAttributes[draggingItem];
    const targetVariantOption = _productAttributes[targetItem];
    _productAttributes[targetItem] = currentVariantOption;
    _productAttributes[draggingItem] = targetVariantOption;
    setProductAttributes([..._productAttributes]);
  };
  const generateProductVariants = () => {
    const _productAttributes = [...productAttributes];
    const _variantCollections = [];
    _productAttributes.forEach((po) => {
      const _options = [];
      po["options"].forEach((ov) => {
        _options.push(ov);
      });
      _variantCollections.push(_options);
    });

    const _variants = cartesianProduct(_variantCollections);
    const _productVariants = [];
    _variants.forEach((v) => {
      _productVariants.push({
        id: _uuid(),
        variantOptions: v,
        variantName: v.join(" / "),
      });
    });
    setProductVariants(_productVariants);
    setFilteredProductVariants(_productVariants);
  };
  const parseAttributeOptions = (options) => {
    const _options = [];
    options.forEach((e) => {
      if (e)
        _options.push({
          value: e,
          label: e,
        });
    });
    return _options;
  };
  function filterVariants(array, criteria) {
    return array.filter((obj) => {
      return criteria.every((criteriaItem) => {
        const index = parseInt(criteriaItem.index);
        return (
          obj.variantOptions[index] &&
          criteriaItem.values.includes(obj.variantOptions[index])
        );
      });
    });
  }

  const filterOptions = (index, values) => {
    const _filteredOptions = { ...filteredOptions };
    _filteredOptions[index] = values;
    setFilteredOptions(_filteredOptions);
    const searchCriteria = [];
    Object.entries(_filteredOptions).forEach((fo) => {
      if (fo[1].length) {
        searchCriteria.push({
          index: fo[0],
          values: fo[1],
        });
      }
    });
    setFilteredProductVariants([
      ...filterVariants(productVariants, searchCriteria),
    ]);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    console.log(e.target.category_id);
    const formData = {
      category_id: e.target.category_id.value,
      industry_id: e.target.industry_id.value,
      title: e.target.title.value,
      unit_measurement: e.target.unit_measurement.value,
      description: e.target.description.value,
      product_options: productAttributes,
      is_variant_finalized: isVariantFinalized,
    };
    updateProduct(id, formData)
      .then((response) => {
        if (response.data.status) {
          _swal(response.data.status, response.data.message);
          navigate("/admin/products");
        }
      })
      .catch((err) => {
        if (err.response.data.errors) {
          const _errorMessages = [];
          Object.entries(err.response.data.errors).forEach((e) => {
            _errorMessages.push(e[1]);
          });
          setErrorMessages([..._errorMessages]);
        }
        _swal(err.response.data.status, err.response.data.message);
      });
  };
  useEffect(() => {
    _fetchCategoryLists();
    _fetchIndustryLists();
    _fetchProduct(id);
    if (!Object.entries(productAttributes).length) {
      addAttribute();
    }
  }, []);
  return (
    <Container className="my-2">
      <Card>
        <Card.Header as="h5">Edit Product</Card.Header>
        <Card.Body>
          <Form onSubmit={onSubmit}>
            {errorMessages.length ? <AppAlert errors={errorMessages} /> : null}
            <Row>
              <Col md="6">
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={2}>
                    Category
                  </Form.Label>
                  <Col sm="8">
                    {productData && productData.category_id ? (
                      <CategorySelect
                        selectedValues={[productData.category_id]}
                      />
                    ) : null}
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={2}>
                    Industry
                  </Form.Label>
                  <Col sm="8">
                    {productData && productData.industry_id ? (
                      <IndustrySelect
                        selectedValues={[productData.industry_id]}
                      />
                    ) : null}
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={2}>
                    Title
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      name="title"
                      placeholder="Title"
                      defaultValue={productData ? productData.title : null}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={2}>
                    Unit Measurement
                  </Form.Label>
                  <Col sm="8">
                    <Form.Select
                      name="unit_measurement"
                      value={productData ? productData.unit_measurement : null}
                    >
                      <option value=""></option>
                      <option value="M">Meter</option>
                      <option value="Ft">Feet</option>
                      <option value="Nos">Number</option>
                      <option value="KG">Kilo Grams</option>
                      <option value="PKT">Packets</option>
                      <option value="D">Drums</option>
                      <option value="L">Liters</option>
                      <option value="ML">Milli Litres</option>
                      <option value="KW">Kilo Watts</option>
                      <option value="A">Amphere</option>
                      <option value="PCS">Pieces</option>
                      <option value="GMS">Grams</option>
                    </Form.Select>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={2}>
                    Description
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      as="textarea"
                      name="description"
                      defaultValue={
                        productData ? productData.description : null
                      }
                    />
                  </Col>
                </Form.Group>
                <Table width={"100%"} bordered>
                  <thead>
                    <tr>
                      <th width="1%">#</th>
                      <th width="19%">Attributes</th>
                      <th width="75%">Options</th>
                      <th width="5%">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productAttributes.length
                      ? productAttributes.map((pv, index) => {
                          return (
                            <tr
                              key={pv["id"]}
                              draggable="true"
                              onDragStart={(e) => handleDragStart(e, index)}
                              onDragEnd={handleDragEnd}
                              onDragOver={handleDragOver}
                              onDrop={(e) => handleDrop(e, index)}
                            >
                              <td>
                                <FaEllipsisVertical />
                              </td>
                              <td>
                                <Form.Control
                                  type="text"
                                  name={"attribute_name" + pv["id"]}
                                  placeholder="Attribute Name"
                                  value={pv["name"]}
                                  onChange={(e) =>
                                    updateAttributeName(
                                      pv["id"],
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <CreatableSelect
                                  isMulti
                                  value={
                                    pv["options"].length
                                      ? parseAttributeOptions(pv["options"])
                                      : []
                                  }
                                  onChange={(e) => {
                                    const _selectedOptions = [];
                                    e.forEach((selectedOption) => {
                                      _selectedOptions.push(
                                        selectedOption.value
                                      );
                                    });
                                    if (_selectedOptions) {
                                      updateAttributeOptions(
                                        pv["id"],
                                        _selectedOptions
                                      );
                                    }
                                  }}
                                />
                              </td>
                              <td>
                                <Button
                                  onClick={() => deleteAttribute(pv["id"])}
                                  variant="warning"
                                  size="sm"
                                >
                                  Delete
                                </Button>
                              </td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </Table>
                <Button
                  onClick={() => addAttribute()}
                  variant="warning"
                  size="sm"
                >
                  Add Attribute
                </Button>
                <Button
                  onClick={() => generateProductVariants()}
                  variant="default"
                  size="sm"
                >
                  Generate Variants
                </Button>
              </Col>
              <Col md="6">
                <Row>
                  <Col md="4">
                    {filteredProductVariants.length &&
                    productAttributes.length !== 0 ? (
                      <>
                        <h5>Filter Variants</h5>
                        <Table border={"0"}>
                          <tbody>
                            {productAttributes.map((pv, index) => {
                              return (
                                <tr>
                                  <td>
                                    <Form.Group>
                                      <Form.Label>{pv["name"]}</Form.Label>
                                      <Select
                                        isMulti
                                        name="categories"
                                        options={
                                          pv["options"].length
                                            ? parseAttributeOptions(
                                                pv["options"]
                                              )
                                            : []
                                        }
                                        onChange={(e) => {
                                          const _selectedOptions = [];
                                          e.forEach((sv) => {
                                            _selectedOptions.push(sv["value"]);
                                          });
                                          filterOptions(
                                            index,
                                            _selectedOptions
                                          );
                                        }}
                                      />
                                    </Form.Group>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </>
                    ) : null}
                  </Col>
                  <Col md="8">
                    {filteredProductVariants.length ? (
                      <div style={{ maxHeight: "500px", overflow: "auto" }}>
                        <Table
                          className="mt-2"
                          bordered
                          responsive
                          width={"100%"}
                        >
                          <thead>
                            <tr>
                              <th>Product Variant</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredProductVariants.length
                              ? filteredProductVariants.map((pv) => {
                                  return (
                                    <tr>
                                      <td>{pv.variantName}</td>
                                    </tr>
                                  );
                                })
                              : null}
                          </tbody>
                        </Table>
                      </div>
                    ) : null}
                  </Col>
                </Row>
              </Col>
            </Row>
            <br />
            <Button type="submit" variant="primary">
              Save as Draft
            </Button>{" "}
            <Button
              type="submit"
              variant="warning"
              onClick={() => setIsVariantFinalized(true)}
            >
              Save & Finalize the Variants
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default EditProduct;
