import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Card,
  Form,
  Button,
  Table,
  Row,
  Col,
  Badge,
} from "react-bootstrap";
import {
  fetchUserDocuments,
  updateUserDocumentStatus,
} from "../../services/userDocuments";
import { _swal, decodeJWT } from "../../utils/helper";
const renderUserDocumentStatus = (status) => {
  switch (status) {
    case "Pending":
      return <Badge bg="info">{status}</Badge>;
    case "Approved":
      return <Badge bg="success">{status}</Badge>;
    case "Rejected":
      return <Badge bg="danger">{status}</Badge>;
    default:
      return <Badge bg="info">{status}</Badge>;
  }
};
function UserDocumentsList() {
  const jwtDecode = decodeJWT();
  const [userDocumentsData, setUserDocumentsData] = useState({});
  const searchSchema = {
    company_name: null,
    status: null,
  };
  const [UserDocumentSearchValues, setUserDocumentSearchValues] =
    useState(searchSchema);
  const _updateUserDocumentStatus = async (id, status) => {
    await updateUserDocumentStatus(id, status)
      .then((response) => {
        const { data } = response;
        _swal(response.data.status, response.data.message);
        _fetchUserDocuments({
          page: 1,
          per_page: 100,
          sort_column: "id",
          sort_order: "DESC",
        });
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };

  const _fetchUserDocuments = async (queryParams = {}) => {
    await fetchUserDocuments(queryParams)
      .then((response) => {
        const { data } = response;
        setUserDocumentsData(data.data.user_documents);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };
  const resetSearch = () => {
    _fetchUserDocuments({
      page: 1,
      per_page: 100,
      sort_column: "id",
      sort_order: "DESC",
    });
  };
  const filterUserDocument = (
    page = null,
    sort_column = null,
    sort_order = null
  ) => {
    const filteredKeys = {};
    Object.entries(UserDocumentSearchValues).filter((v) => {
      if (v[1] !== null) {
        filteredKeys[v[0]] = v[1];
      }
    });
    _fetchUserDocuments({
      ...filteredKeys,
      page: page ? page : 1,
      per_page: 100,
      sort_column: sort_column ? sort_column : "id",
      sort_order: sort_order ? sort_order : "DESC",
    });
  };
  useEffect(() => {
    _fetchUserDocuments({
      page: 1,
      per_page: 100,
      sort_column: "id",
      sort_order: "DESC",
    });
  }, []);
  return (
    <Container className="my-2">
      <Card>
        <Card.Header as="h5">
          {jwtDecode && jwtDecode.user_role === "S"
            ? "My Documents"
            : "User Documents"}{" "}
          {jwtDecode && jwtDecode.user_role === "S" ? (
            <Button
              variant="primary"
              as={Link}
              to={"/seller/documents/create"}
              size="sm"
            >
              Upload
            </Button>
          ) : null}
        </Card.Header>
        <Card.Body>
          <Form>
            <Row>
              <Col md="3">
                <Form.Group className="mb-3">
                  <Form.Label>Company Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="company_name"
                    placeholder="Company Name"
                    value={
                      UserDocumentSearchValues.company_name !== null
                        ? UserDocumentSearchValues.company_name
                        : ""
                    }
                    onChange={(e) =>
                      setUserDocumentSearchValues({
                        ...UserDocumentSearchValues,
                        company_name: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>

            <Button
              onClick={() => filterUserDocument()}
              variant="primary"
              className="mr-2"
            >
              Filter
            </Button>
            <Button
              onClick={(e) => {
                setUserDocumentSearchValues({ ...searchSchema });
                resetSearch();
              }}
              variant="default"
            >
              Reset
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <Table bordered width={"100%"} className="mt-2">
        <thead>
          <tr>
            <th width="15%">Company Name</th>
            <th width="55%">Docs</th>
            <th width="10%" className="text-center">
              Status
            </th>
            <th width="20%" className="text-center">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {userDocumentsData.length ? (
            userDocumentsData.map((e) => {
              return (
                <tr>
                  <td>{e.company_name}</td>
                  <td>
                    {e.file_path &&
                      JSON.parse(e.file_path).map((fl) => {
                        return (
                          <a href={fl} target="_blank">
                            <Badge bg="info" className="m-1" text="dark">
                              {fl
                                .toString()
                                .replace(
                                  "https://eprocureglobal-prod.s3.ap-south-1.amazonaws.com/",
                                  ""
                                )}
                            </Badge>
                          </a>
                        );
                      })}
                  </td>
                  <td className="text-center">{renderUserDocumentStatus(e.status)}</td>
                  <td className="text-center">
                    {jwtDecode &&
                    jwtDecode.user_role === "A" &&
                    e.status === "Pending" ? (
                      <>
                        <Button
                          variant="success"
                          onClick={() => {
                            _updateUserDocumentStatus(e.id, "Approved");
                          }}
                          size="sm"
                        >
                          Approve
                        </Button>{" "}
                        <Button
                          variant="warning"
                          onClick={() => {
                            _updateUserDocumentStatus(e.id, "Rejected");
                          }}
                          size="sm"
                        >
                          Reject
                        </Button>
                      </>
                    ) : null}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={"4"}>User Documents not found</td>
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  );
}

export default UserDocumentsList;
