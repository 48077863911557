import { _APIHandler } from "../utils/helper";

let baseUrl = "carts";
export const fetchCart = () => _APIHandler.get(`${baseUrl}`);
export const fetchCartItems = () => _APIHandler.get(`${baseUrl}/cartItems`);
export const fetchCartSellerComparisons = (data) => _APIHandler.post(`${baseUrl}/sellerComparisons`, data);
export const addToCart = (data) => _APIHandler.post(`${baseUrl}`, data);
export const updateCart = (data) => _APIHandler.post(`${baseUrl}/cartItems`, data);
export const updateCartItem = (id, data) => _APIHandler.put(`${baseUrl}/${id}/cartItems`, data);
export const updateCartSeller = (data) => _APIHandler.put(`${baseUrl}/sellerDetails`, data);
export const deleteCartItem = (id) => _APIHandler.delete(`${baseUrl}/${id}/cartItems`);