import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { signup } from "../../services/auth";
import { _swal } from "../../utils/helper";
import AppAlert from "../../components/AppAlert";
import CountrySelect from "../../components/Input/CountrySelect";
function SignUp(props) {
  const { onHandleCurrentAuthModalForm } = props;
  const [errorMessages, setErrorMessages] = useState([]);
  const captchaRef = useRef(null);
  const onSubmit = (e) => {
    e.preventDefault();
    const token = captchaRef.current.getValue();
    captchaRef.current.reset();
    const formData = {
      first_name: e.target.first_name.value,
      last_name: e.target.last_name.value,
      email: e.target.email.value,
      password: e.target.password.value,
      user_role: e.target.user_role.value,
      company_name: e.target.company_name.value,
      address_1: e.target.address_1.value,
      address_2: e.target.address_2.value,
      city: e.target.city.value,
      country_id: e.target.country_id.value,
      zip_code: e.target.zip_code.value,
      phone_number: e.target.phone_number.value,
      recaptcha_token: token,
    };
    signup(formData)
      .then((response) => {
        if (response.data.status) {
          _swal(response.data.status, response.data.message);
          e.target.reset();
          window.location.href = "/";
        }
      })
      .catch((err) => {
        if (err.response.data && err.response.data.errors) {
          const _errorMessages = [];
          Object.entries(err.response.data.errors).forEach((e) => {
            _errorMessages.push(e[1]);
          });
          setErrorMessages([..._errorMessages]);
        }
        _swal(err.response.data.status, err.response.data.message);
      });
  };
  return (
    <Card>
      <Card.Header as="h5">Sign Up</Card.Header>
      <Card.Body>
        {errorMessages.length ? <AppAlert errors={errorMessages} /> : null}
        <Form onSubmit={onSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>I'm a *</Form.Label>
            <Form.Select name="user_role">
              <option value="B">Buyer</option>
              <option value="S">Seller</option>
            </Form.Select>
          </Form.Group>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>First Name *</Form.Label>
                <Form.Control
                  type="text"
                  name="first_name"
                  placeholder="First Name"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Last Name *</Form.Label>
                <Form.Control
                  type="text"
                  name="last_name"
                  placeholder="Last Name"
                />
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="mb-3">
            <Form.Label>Email *</Form.Label>
            <Form.Control type="email" name="email" placeholder="Email" />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              name="password"
              placeholder="Password"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Company Name *</Form.Label>
            <Form.Control
              type="text"
              name="company_name"
              placeholder="Company Name"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Address 1 *</Form.Label>
            <Form.Control
              type="text"
              name="address_1"
              placeholder="Address 1"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Address 2</Form.Label>
            <Form.Control
              type="text"
              name="address_2"
              placeholder="Address 2"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>City *</Form.Label>
            <Form.Control type="text" name="city" placeholder="City" />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Country *</Form.Label>
            <CountrySelect
              inputName="country_id"
              isMultiple={false}
              defaultOptions={[]}
              onChangeCountryOption={null}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Zip Code *</Form.Label>
            <Form.Control type="text" name="zip_code" placeholder="Zip Code" />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Phone Number *</Form.Label>
            <Form.Control
              type="text"
              name="phone_number"
              placeholder="Phone Number"
            />
          </Form.Group>
          <ReCAPTCHA
            sitekey={"6LcmcLwpAAAAAEZuNCVay2pUKPzOCBSNOp0wUFck"}
            ref={captchaRef}
          />
          <div className="d-grid gap-2">
            <Button type="submit" variant="primary">
              Sign Up
            </Button>
          </div>
        </Form>
        <p className="mt-4">
          Already have an account?{" "}
          <Button
            variant="dark"
            onClick={() => onHandleCurrentAuthModalForm("SignIn")}
            size="sm"
          >
            Sign In
          </Button>
        </p>
      </Card.Body>
    </Card>
  );
}

export default SignUp;
