import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Card,
  Form,
  Button,
  Row,
  Col,
  Table,
  Badge,
} from "react-bootstrap";
import Select from "react-select";
import { FaPlus, FaMinus } from "react-icons/fa6";
import { fetchCountries } from "../../services/meta";
import {
  fetchSellerProductListsByCountries,
  fetchSellerProductByProductId,
} from "../../services/sellerProducts";
import {
  addToCart,
  fetchCart,
  fetchCartItems,
  deleteCartItem,
  fetchCartSellerComparisons,
  updateCartSeller,
} from "../../services/cart";
import { raiseSPPurchaseOrder } from "../../services/purchaseOrders";
import AppAlert from "../../components/AppAlert";
import { _formatCurrencyPrice, _swal, _units, _uuid } from "../../utils/helper";
import { FaCheckCircle } from "react-icons/fa";
import { fetchApprovedCompanyLists } from "../../services/approvedCompanies";
function CreatePurchaseOrder() {
  const navigate = useNavigate();
  const [countrySelection, setCountrySelection] = useState("A");
  const [approvedCompanySelection, setApprovedCompanySelection] = useState("A");
  const [currentStep, setCurrentStep] = useState(1);
  const [countryListData, setCountryListData] = useState([]);
  const [approvedCompanyListData, setApprovedCompanyListData] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedApprovedCompanies, setSelectedApprovedCompanies] = useState(
    []
  );
  const [productListData, setProductListData] = useState([]);
  const [productAttributes, setProductAttributes] = useState([]);
  const [productVariants, setProductVariants] = useState([]);
  const [filteredProductVariants, setFilteredProductVariants] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState({});
  const [cartData, setCartData] = useState({});
  const [cartItemTempData, setCartItemTempData] = useState({});
  const [cartItemSellerData, setCartItemSellerData] = useState({});
  const [sellerComparisonData, setSellerComparisonData] = useState({});
  const [errorMessages, setErrorMessages] = useState([]);
  const _fetchCountries = async () => {
    await fetchCountries()
      .then((response) => {
        const { data } = response;
        const _options = [];
        data.data.countries.forEach((v) => {
          _options.push({
            value: v["id"],
            label: v["name"],
          });
        });
        setCountryListData(_options);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };
  const _fetchApprovedCompanyLists = async () => {
    await fetchApprovedCompanyLists()
      .then((response) => {
        const { data } = response;
        const _options = [];
        data.data.approved_companies.forEach((v) => {
          _options.push({
            value: v["id"],
            label: v["name"],
          });
        });
        setApprovedCompanyListData(_options);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };
  const _fetchSellerProductListsByCountries = async () => {
    const _selected_countries = [];
    if (selectedCountries) {
      selectedCountries.forEach((v) => {
        _selected_countries.push(v.value);
      });
    }
    const _selected_approved_companies = [];
    if (selectedApprovedCompanies) {
      selectedApprovedCompanies.forEach((v) => {
        _selected_approved_companies.push(v.value);
      });
    }
    const _formData = {
      countries: _selected_countries,
      approved_companies: _selected_approved_companies,
    };
    await fetchSellerProductListsByCountries(_formData)
      .then((response) => {
        const { data } = response;
        const _options = [];
        if (data.data.products.length === 0) {
          _swal(0, "There is no products available");
        } else {
          data.data.products.forEach((v) => {
            _options.push({
              value: v["id"],
              label: v["title"],
            });
          });
        }
        setProductListData(_options);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };
  const _fetchSellerProductByProductId = async (id) => {
    await fetchSellerProductByProductId(id)
      .then((response) => {
        const { data } = response;
        setProductAttributes(JSON.parse(data.data.product.product_options));
        if (data.data.product_variants) {
          const _productVariants = [];
          data.data.product_variants.forEach((v) => {
            console.log(v);
            _productVariants.push({
              productId: data.data.product.id,
              productVariantId: v["product_variant_id"],
              variantOptions: v["product_variant_title"].split(":"),
              variantName: v["product_variant_title"].replaceAll(":", " / "),
            });
          });
          setProductVariants(_productVariants);
          setFilteredProductVariants(_productVariants);
        }
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response.data);
      });
  };
  const parseAttributeOptions = (options) => {
    const _options = [];
    options.forEach((e) => {
      if (e)
        _options.push({
          value: e,
          label: e,
        });
    });
    return _options;
  };
  function filterVariants(array, criteria) {
    return array.filter((obj) => {
      return criteria.every((criteriaItem) => {
        const index = parseInt(criteriaItem.index);
        return (
          obj.variantOptions[index] &&
          criteriaItem.values.includes(obj.variantOptions[index])
        );
      });
    });
  }
  const filterOptions = (index, values) => {
    const _filteredOptions = { ...filteredOptions };
    _filteredOptions[index] = values;
    setFilteredOptions(_filteredOptions);
    const searchCriteria = [];
    Object.entries(_filteredOptions).forEach((fo) => {
      if (fo[1].length) {
        searchCriteria.push({
          index: fo[0],
          values: fo[1],
        });
      }
    });
    setFilteredProductVariants([
      ...filterVariants([...productVariants], searchCriteria),
    ]);
  };
  const _addToCart = async (product_variant_id, label) => {
    const _cartItemTempData = { ...cartItemTempData };
    _cartItemTempData[product_variant_id] = {
      label,
      quantity: 1,
    };
    setCartItemTempData({ ..._cartItemTempData });
    _swal(1, "Successfully added from your cart");
  };
  const _deleteCartItem = async (id) => {
    const _cartItemTempData = { ...cartItemTempData };
    delete _cartItemTempData[id];
    setCartItemTempData({ ..._cartItemTempData });
    _swal(1, "Successfully removed from your cart");
  };
  const _fetchCartSellerComparisons = async () => {
    const _data = {};
    _data["productVariants"] = [];
    Object.entries(cartItemTempData).forEach(([i, v]) => {
      _data["productVariants"].push(i);
    });
    await fetchCartSellerComparisons(_data)
      .then((response) => {
        const { data } = response;
        setSellerComparisonData(data.data.comparisonResults);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };
  const updateCartItemSellerQtyData = (
    sellerId,
    sellerProductMakeId,
    value
  ) => {
    const _cartItemSellerData = { ...cartItemSellerData };
    if (_cartItemSellerData[sellerId] === undefined) {
      _cartItemSellerData[sellerId] = {};
    }
    _cartItemSellerData[sellerId][sellerProductMakeId] = value;
    setCartItemSellerData(_cartItemSellerData);
  };
  const _raiseSPPurchaseOrderonSubmit = async () => {
    await raiseSPPurchaseOrder({
      jsonData: cartItemSellerData,
    })
      .then((response) => {
        if (response.data.status) {
          _swal(response.data.status, response.data.message);
          navigate("/purchase_orders");
        }
      })
      .catch((err) => {
        if (err.response.data.errors) {
          const _errorMessages = [];
          Object.entries(err.response.data.errors).forEach((e) => {
            _errorMessages.push(e[1]);
          });
          setErrorMessages([..._errorMessages]);
        }
        _swal(err.response.data.status, err.response.data.message);
      });
  };
  useEffect(() => {
    _fetchCountries();
    _fetchApprovedCompanyLists();
  }, []);
  return (
    <Container className="my-2">
      <Card>
        <Card.Header as="h5">Marketplace</Card.Header>
      </Card>
      <Form>
        {errorMessages.length ? <AppAlert errors={errorMessages} /> : null}

        <>
          <Card className="mt-2">
            <Card.Header
              style={
                currentStep === 1
                  ? { background: "rgb(54, 147, 61)", color: "white" }
                  : {}
              }
              as="h6"
            >
              1. Choose Countries & Approved Companies{" "}
              {currentStep === 2 || currentStep === 3 ? (
                <FaCheckCircle color="green" size={"20px"} />
              ) : null}
            </Card.Header>
            <Card.Body>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Countries
                </Form.Label>
                <Col sm="4">
                  {currentStep === 1 ? (
                    <>
                      <Form.Select
                        onChange={(e) => {
                          setCountrySelection(e.target.value);
                          if (e.target.value === "A") {
                            setSelectedCountries([]);
                          }
                        }}
                      >
                        <option value="A">All Countries</option>
                        <option value="S">Specific Countries</option>
                      </Form.Select>
                      {countrySelection === "S" ? (
                        <Select
                          className="mt-2"
                          placeholder="Choose your Specific Countries"
                          isMulti
                          name="countries"
                          options={countryListData}
                          onChange={(e) => {
                            const _selectedCountries = [];
                            e.forEach((sv) => {
                              _selectedCountries.push({
                                label: sv["label"],
                                value: sv["value"],
                              });
                            });
                            setSelectedCountries(_selectedCountries);
                          }}
                        />
                      ) : null}
                    </>
                  ) : (
                    <>
                      {selectedCountries.length ? (
                        selectedCountries.map((v) => {
                          return (
                            <Badge bg="dark" className="mx-1 mt-2">
                              {v["label"]}
                            </Badge>
                          );
                        })
                      ) : (
                        <Badge bg="dark" className="mx-1 mt-2">
                          All Countries
                        </Badge>
                      )}
                    </>
                  )}
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Approved Companies
                </Form.Label>
                <Col sm="4">
                  {currentStep === 1 ? (
                    <>
                      <Form.Select
                        onChange={(e) => {
                          setApprovedCompanySelection(e.target.value);
                          if (e.target.value === "A") {
                            setSelectedApprovedCompanies([]);
                          }
                        }}
                      >
                        <option value="A">All Companies</option>
                        <option value="S">Specific Companies</option>
                      </Form.Select>
                      {approvedCompanySelection === "S" ? (
                        <Select
                          className="mt-2"
                          placeholder="Choose your Specific Companies"
                          isMulti
                          name="approved_companies"
                          options={approvedCompanyListData}
                          onChange={(e) => {
                            const _selectedApprovedCompanies = [];
                            e.forEach((sv) => {
                              _selectedApprovedCompanies.push({
                                label: sv["label"],
                                value: sv["value"],
                              });
                            });
                            setSelectedApprovedCompanies(
                              _selectedApprovedCompanies
                            );
                          }}
                        />
                      ) : null}
                    </>
                  ) : (
                    <>
                      {selectedApprovedCompanies.length ? (
                        selectedApprovedCompanies.map((v) => {
                          return (
                            <Badge bg="dark" className="mx-1 mt-2">
                              {v["label"]}
                            </Badge>
                          );
                        })
                      ) : (
                        <Badge bg="dark" className="mx-1 mt-2">
                          All Approved Companies
                        </Badge>
                      )}
                    </>
                  )}
                </Col>
              </Form.Group>
              {currentStep === 1 ? (
                <Button
                  type="button"
                  onClick={() => {
                    setCurrentStep(2);
                    _fetchSellerProductListsByCountries();
                  }}
                >
                  Continue & Next
                </Button>
              ) : null}
            </Card.Body>
          </Card>
        </>
        <>
          <Card className="mt-2">
            <Card.Header
              style={
                currentStep === 2
                  ? { background: "rgb(54, 147, 61)", color: "white" }
                  : {}
              }
              as="h6"
            >
              2. Choose Products{" "}
              {currentStep === 3 ? (
                <FaCheckCircle color="green" size={"20px"} />
              ) : null}
            </Card.Header>
            <Card.Body>
              {currentStep === 2 ? (
                <>
                  <Row>
                    <Col md="4">
                      <Form.Group className="mb-3">
                        <Select
                          name="products"
                          options={productListData}
                          onChange={(e) => {
                            if (e) _fetchSellerProductByProductId(e.value);
                          }}
                        />
                      </Form.Group>
                      {productAttributes.length !== 0 ? (
                        <>
                          <h5>Filter Variants</h5>
                          {productAttributes.map((pv, index) => {
                            return (
                              <Form.Group as={Row} className="mt-2">
                                <Form.Label>{pv["name"]}</Form.Label>
                                <Select
                                  isMulti
                                  name="categories"
                                  options={
                                    pv["options"].length
                                      ? parseAttributeOptions(pv["options"])
                                      : []
                                  }
                                  onChange={(e) => {
                                    const _selectedOptions = [];
                                    e.forEach((sv) => {
                                      _selectedOptions.push(sv["value"]);
                                    });
                                    filterOptions(index, _selectedOptions);
                                  }}
                                />
                              </Form.Group>
                            );
                          })}
                        </>
                      ) : null}
                    </Col>
                    <Col md="4">
                      {filteredProductVariants.length ? (
                        <div style={{ maxHeight: "500px", overflow: "auto" }}>
                          <Table
                            className="mt-2"
                            bordered
                            responsive
                            width={"100%"}
                          >
                            <thead>
                              <tr>
                                <th width="100%">Product Variant</th>
                              </tr>
                            </thead>
                            <tbody>
                              {filteredProductVariants.length
                                ? filteredProductVariants.map((pv) => {
                                    return (
                                      <tr key={pv.productVariantId}>
                                        <td>
                                          {currentStep === 2 ? (
                                            cartItemTempData[
                                              pv.productVariantId
                                            ] ? (
                                              <Badge
                                                bg="warning"
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                                key={pv.productVariantId}
                                                onClick={() =>
                                                  _deleteCartItem(
                                                    pv.productVariantId
                                                  )
                                                }
                                              >
                                                <FaMinus />
                                              </Badge>
                                            ) : (
                                              <Badge
                                                bg="primary"
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                                key={pv.productVariantId}
                                                onClick={() =>
                                                  _addToCart(
                                                    pv.productVariantId,
                                                    pv.variantName
                                                  )
                                                }
                                              >
                                                <FaPlus />
                                              </Badge>
                                            )
                                          ) : null}{" "}
                                          {pv.variantName}
                                        </td>
                                      </tr>
                                    );
                                  })
                                : null}
                            </tbody>
                          </Table>
                        </div>
                      ) : null}
                    </Col>
                    <Col md="4">
                      <Table bordered>
                        <thead>
                          <tr>
                            <th>Selected Products</th>
                          </tr>
                        </thead>
                        <tbody>
                          {cartItemTempData ? (
                            Object.entries(cartItemTempData).map(([i, v]) => {
                              return (
                                <tr>
                                  <td>
                                    <Badge
                                      bg="warning"
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      key={i}
                                      onClick={() => _deleteCartItem(i)}
                                    >
                                      <FaMinus />
                                    </Badge>{" "}
                                    {`${v["label"]})`}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td>No Items added yet</td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                  {cartItemTempData &&
                  Object.entries(cartItemTempData).length ? (
                    <Button
                      type="button"
                      variant="warning"
                      className="mt-2"
                      onClick={() => {
                        _fetchCartSellerComparisons();
                        setCurrentStep(3);
                      }}
                    >
                      Continue
                    </Button>
                  ) : null}
                </>
              ) : null}
              {currentStep === 3 ? (
                <Table bordered>
                  <thead>
                    <tr>
                      <th>Selected Products</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cartData && cartData["cart_items"] ? (
                      cartData["cart_items"].map((civ) => {
                        return (
                          <tr>
                            <td>
                              {`${civ["products"]["title"]} (${civ["product_variants"]["title"]})`}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td>No Items added yet</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              ) : null}
            </Card.Body>
          </Card>
        </>
        <Card className="mt-2">
          <Card.Header
            style={
              currentStep === 3
                ? { background: "rgb(54, 147, 61)", color: "white" }
                : {}
            }
            as="h6"
          >
            3. Rate Comparison Sheet
          </Card.Header>
          <Card.Body>
            {currentStep === 3 ? (
              <>
                <Table bordered className="mb-0">
                  <tbody>
                    {sellerComparisonData
                      ? Object.entries(sellerComparisonData).map((vcv) => {
                          const cartItem = vcv[1];
                          return (
                            <>
                              <tr>
                                <td
                                  style={{
                                    background: "rgb(54, 147, 61)",
                                    color: "white",
                                  }}
                                  colSpan={"11"}
                                >
                                  <span>{cartItem["productTitle"]}</span>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0">
                                  {cartItem["sellerLists"] ? (
                                    <Table
                                      bordered
                                      size="sm"
                                      width={"100%"}
                                      className="m-0"
                                    >
                                      <thead background={"green.100"}>
                                        <tr>
                                          <th width="5%">Seller</th>
                                          <th width="18%">Make</th>
                                          <th width="12%">Country Origin</th>
                                          <th width="10%">Inspection</th>
                                          <th width="10%">Payment</th>
                                          <th width="10%">Delivery Temrs</th>
                                          <th
                                            width="6%"
                                            style={{ textAlign: "right" }}
                                          >
                                            VAT (%)
                                          </th>
                                          <th
                                            width="10%"
                                            style={{ textAlign: "right" }}
                                          >
                                            Available Qty
                                          </th>
                                          <th
                                            width={"10%"}
                                            style={{ textAlign: "right" }}
                                          >
                                            Unit Price
                                          </th>
                                          <th
                                            width={"10%"}
                                            style={{ textAlign: "right" }}
                                          >
                                            Order Qty
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {cartItem["sellerLists"].map((sl) => {
                                          return sl["seller_product_makes"]
                                            .length
                                            ? sl["seller_product_makes"].map(
                                                (spb) => {
                                                  return (
                                                    <tr>
                                                      <td>TBA</td>
                                                      <td>
                                                        {spb["make_name"]}
                                                      </td>
                                                      <td>
                                                        {
                                                          spb[
                                                            "country_of_Origin"
                                                          ]
                                                        }
                                                      </td>
                                                      <td>
                                                        {spb["inspection"]}
                                                      </td>
                                                      <td>{spb["payment"]}</td>
                                                      <td>
                                                        {spb["delivery_terms"]}
                                                      </td>
                                                      <td
                                                        style={{
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        {spb["vat"]} %
                                                      </td>
                                                      <td
                                                        style={{
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        {spb["available_stock"]}{" "}
                                                        {_units(
                                                          cartItem[
                                                            "productUnit"
                                                          ]
                                                        )}
                                                      </td>
                                                      <td
                                                        style={{
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        {_formatCurrencyPrice(
                                                          spb["price"]
                                                        )}
                                                      </td>
                                                      <td
                                                        style={{
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        <Form.Control
                                                          type="number"
                                                          style={{
                                                            textAlign: "right",
                                                          }}
                                                          max={
                                                            spb[
                                                              "available_stock"
                                                            ]
                                                          }
                                                          defaultValue={
                                                            cartItemSellerData[
                                                              sl["seller_id"]
                                                            ] !== undefined &&
                                                            cartItemSellerData[
                                                              sl["seller_id"]
                                                            ][
                                                              spb[
                                                                "seller_product_make_id"
                                                              ]
                                                            ] !== undefined
                                                              ? cartItemSellerData[
                                                                  sl[
                                                                    "seller_id"
                                                                  ]
                                                                ][
                                                                  spb[
                                                                    "seller_product_make_id"
                                                                  ]
                                                                ]
                                                              : ""
                                                          }
                                                          onChange={(e) => {
                                                            updateCartItemSellerQtyData(
                                                              sl["seller_id"],
                                                              spb[
                                                                "seller_product_make_id"
                                                              ],
                                                              e.target.value
                                                            );
                                                          }}
                                                        />
                                                      </td>
                                                    </tr>
                                                  );
                                                }
                                              )
                                            : null;
                                        })}
                                      </tbody>
                                    </Table>
                                  ) : null}
                                </td>
                              </tr>
                            </>
                          );
                        })
                      : null}
                  </tbody>
                </Table>
                {Object.entries(sellerComparisonData).length ? (
                  <Button
                    className="ml-2 mt-2"
                    onClick={() => _raiseSPPurchaseOrderonSubmit()}
                  >
                    Raise LOI
                  </Button>
                ) : null}
              </>
            ) : null}
          </Card.Body>
        </Card>
      </Form>
    </Container>
  );
}

export default CreatePurchaseOrder;
