import { useState, useEffect } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import Select from "react-select";
import {
  fetchCategoryLists,
  fetchUserCategories,
  updateUserCategory,
} from "../../services/categories";
import AppAlert from "../../components/AppAlert";
import { _swal } from "../../utils/helper";
function MyCategories() {
  const [errorMessages, setErrorMessages] = useState([]);
  const [categoryListOptions, setCategoryListOptions] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [defaultOptions, setDefaultOptions] = useState([]);
  const _fetchCategoryLists = async (queryParams = {}) => {
    await fetchCategoryLists(queryParams)
      .then((response) => {
        const { data } = response;
        const _options = [];
        data.data.categories.forEach((v) => {
          _options.push({
            value: v["id"],
            label: v["name"],
          });
        });
        setCategoryListOptions(_options);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };
  const _fetchUserCategories = async () => {
    await fetchUserCategories()
      .then((response) => {
        const { data } = response;
        const _options = [];
        data.data.categories.forEach((v) => {
          _options.push({
            value: v["category_id"],
            label: v["Category"]["name"],
          });
        });
        setDefaultOptions(_options);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    updateUserCategory({
      categoryIds: selectedCategories,
    })
      .then((response) => {
        if (response.data.status) {
          _swal(response.data.status, response.data.message);
        }
      })
      .catch((err) => {
        if (err.response.data.errors) {
          const _errorMessages = [];
          Object.entries(err.response.data.errors).forEach((e) => {
            _errorMessages.push(e[1]);
          });
          setErrorMessages([..._errorMessages]);
        }
        _swal(err.response.data.status, err.response.data.message);
      });
  };
  useEffect(() => {
    _fetchCategoryLists();
    _fetchUserCategories();
  }, []);
  return (
    <Container className="my-5">
      <Row>
        <Col md="3"></Col>
        <Col md="6">
          <Card>
            <Card.Header as="h5">My Categories</Card.Header>
            <Card.Body>
              <Form onSubmit={onSubmit}>
                {errorMessages.length ? (
                  <AppAlert errors={errorMessages} />
                ) : null}
                <Form.Group className="mb-3">
                  <Form.Label>Categories *</Form.Label>
                  <Select
                    isMulti
                    name="categories"
                    options={categoryListOptions}
                    value={defaultOptions}
                    onChange={(e) => {
                      const _selectedOptions = [];
                      const _selectedOptions2 = [];
                      e.forEach((sv) => {
                        _selectedOptions.push(sv["value"]);
                        _selectedOptions2.push({
                          value: sv["value"],
                          label: sv["label"],
                        });
                      });
                      setSelectedCategories(_selectedOptions);
                      setDefaultOptions(_selectedOptions2);
                    }}
                  />
                </Form.Group>
                <Button type="submit" variant="primary">
                  Submit
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
        <Col md="3"></Col>
      </Row>
    </Container>
  );
}

export default MyCategories;
