import {
  Container,
  Button,
  Row,
  Col,
  Card,
  Form,
  InputGroup,
} from "react-bootstrap";
import AppFooter from "../components/AppFooter";
import { fetchCountries, sendContactReq } from "../services/meta";
import { _swal } from "../utils/helper";
import { FaEnvelope, FaMobile, FaWhatsapp } from "react-icons/fa6";
import { useState, useEffect } from "react";
export default function ContactUs() {
  const [countryListData, setCountryListData] = useState([]);
  const onSubmit = (e) => {
    e.preventDefault();
    const formData = {
      name: e.target.name.value,
      company_name: e.target.company_name.value,
      email: e.target.email.value,
      country_code: e.target.country_code.value,
      phone_number: e.target.phone_number.value,
      message: e.target.message.value,
    };
    sendContactReq(formData)
      .then((response) => {
        if (response.data.status) {
          _swal(response.data.status, response.data.message);
          e.target.reset();
        }
      })
      .catch((err) => {
        _swal(err.response.data.status, err.response.data.message);
      });
  };
  const _fetchCountries = async () => {
    await fetchCountries()
      .then((response) => {
        const { data } = response;
        const _countryCodes = [];
        data.data.countries.forEach((e) => {
          if (!_countryCodes.includes(e["code"])) _countryCodes.push(e["code"]);
        });
        setCountryListData(_countryCodes);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };
  useEffect(() => {
    _fetchCountries();
  }, []);
  return (
    <>
      <div
        style={{
          background: "rgb(54, 147, 61)",
          color: "white",
        }}
      >
        <Container className="py-3">
          <h2>
            <strong>Get in Touch | Have a query? Contact us now!</strong>
          </h2>
        </Container>
      </div>
      <Container className="my-5">
        <Row>
          <Col md="6">
            <h2>CONTACT US..</h2>
            <p>
              <FaEnvelope /> Email :{" "}
              <a href="mailto:admin@eprocureglobal.com">
                admin@eprocureglobal.com
              </a>
            </p>
            <p>
              <FaWhatsapp /> Whats app :{" "}
              <a href="tel:919876543210">+91 98765 43210</a>
            </p>
            <p>
              <FaMobile /> Contact Number :{" "}
              <a href="tel:919876543210">+91 98765 43210</a>
            </p>
          </Col>
          <Col md="6">
            <Card>
              <Card.Header>
                <b>SEND US AN EMAIL</b>
              </Card.Header>
              <Card.Body>
                <Form onSubmit={onSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Label>Name *</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      placeholder="Name"
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Company Name *</Form.Label>
                    <Form.Control
                      type="text"
                      name="company_name"
                      placeholder="Company Name"
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Email address *</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder="Enter email"
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Phone Number *</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text className="p-0">
                        <Form.Select name="country_code">
                          <option value="">Country Code</option>
                          {countryListData.length !== 0
                            ? countryListData.map((v) => {
                                return <option value={v}>+{v}</option>;
                              })
                            : null}
                        </Form.Select>
                      </InputGroup.Text>
                      <Form.Control
                        type="number"
                        name="phone_number"
                        placeholder="Phone Number"
                        required
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Message *</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="message"
                      placeholder="Message"
                      required
                    />
                  </Form.Group>
                  <Button type="submit" variant="primary">
                    Submit
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <AppFooter />
    </>
  );
}
