import { Container, Row, Col } from "react-bootstrap";
import ForgotPassword from "./ForgotPassword";
function ForgotPasswordPage() {
  return (
    <Container className="my-5">
      <Row>
        <Col md="4"></Col>
        <Col md="4">
          <ForgotPassword />
        </Col>
        <Col md="4"></Col>
      </Row>
    </Container>
  );
}

export default ForgotPasswordPage;
