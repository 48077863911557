import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Card, Form, Row, Col, Table, Badge } from "react-bootstrap";
import Select from "react-select";
import { fetchDetailProduct } from "../../services/products";
import { _uuid, cartesianProduct } from "../../utils/helper";
function ViewProduct() {
  const { id } = useParams();
  const [productData, setProductData] = useState({});
  const [productAttributes, setProductAttributes] = useState([]);
  const [productVariants, setProductVariants] = useState([]);
  const [filteredProductVariants, setFilteredProductVariants] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState({});
  const _fetchDetailProduct = async (id) => {
    await fetchDetailProduct(id)
      .then((response) => {
        const { data } = response;
        setProductData(data.data.product);
        console.log("product", data.data.product);
        setProductAttributes([
          ...JSON.parse(data.data.product.product_options),
        ]);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };
  const generateProductVariants = () => {
    const _productAttributes = [...productAttributes];
    const _variantCollections = [];
    _productAttributes.forEach((po) => {
      const _options = [];
      po["options"].forEach((ov) => {
        _options.push(ov);
      });
      _variantCollections.push(_options);
    });

    const _variants = cartesianProduct(_variantCollections);
    const _productVariants = [];
    _variants.forEach((v) => {
      _productVariants.push({
        id: _uuid(),
        variantOptions: v,
        variantName: v.join(" / "),
      });
    });
    setProductVariants(_productVariants);
    setFilteredProductVariants(_productVariants);
  };
  const parseAttributeOptions = (options) => {
    const _options = [];
    options.forEach((e) => {
      if (e)
        _options.push({
          value: e,
          label: e,
        });
    });
    return _options;
  };
  function filterVariants(array, criteria) {
    return array.filter((obj) => {
      return criteria.every((criteriaItem) => {
        const index = parseInt(criteriaItem.index);
        return (
          obj.variantOptions[index] &&
          criteriaItem.values.includes(obj.variantOptions[index])
        );
      });
    });
  }

  const filterOptions = (index, values) => {
    const _filteredOptions = { ...filteredOptions };
    _filteredOptions[index] = values;
    setFilteredOptions(_filteredOptions);
    const searchCriteria = [];
    Object.entries(_filteredOptions).forEach((fo) => {
      if (fo[1].length) {
        searchCriteria.push({
          index: fo[0],
          values: fo[1],
        });
      }
    });
    setFilteredProductVariants([
      ...filterVariants(productVariants, searchCriteria),
    ]);
  };
  useEffect(() => {
    _fetchDetailProduct(id);
    // generateProductVariants();
  }, []);
  return (
    <Container className="my-2">
      <Card>
        <Card.Header as="h5">{productData.title} - Product</Card.Header>
        <Card.Body>
          <Form>
            <Row>
              <Col md="6">
                <Table bordered>
                  <tbody>
                    <tr>
                      <td width={"25%"}>
                        <strong>Category</strong>
                      </td>
                      <td width={"75%"}>
                        {productData.categories
                          ? productData.categories.name
                          : null}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Industry</strong>
                      </td>
                      <td>
                        {productData.industries
                          ? productData.industries.name
                          : null}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Description</strong>
                      </td>
                      <td>{productData.description}</td>
                    </tr>
                  </tbody>
                </Table>
                <Table width={"100%"} bordered>
                  <thead>
                    <tr>
                      <th width="20%">Attributes</th>
                      <th width="80%">Options</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productAttributes.length
                      ? productAttributes.map((pv, index) => {
                          return (
                            <tr key={pv["id"]}>
                              <td>{pv["name"]}</td>
                              <td>
                                {pv["options"].map((ov) => {
                                  return (
                                    <Badge
                                      bg="warning"
                                      className="mx-1"
                                      text="dark"
                                    >
                                      {ov}
                                    </Badge>
                                  );
                                })}
                              </td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </Table>
              </Col>
              <Col md="6">
                <Row>
                  <Col md="4">
                    {filteredProductVariants.length &&
                    productAttributes.length !== 0 ? (
                      <>
                        <h5>Filter Variants</h5>
                        <Table border={"0"}>
                          <tbody>
                            {productAttributes.map((pv, index) => {
                              return (
                                <tr>
                                  <td>
                                    <Form.Group>
                                      <Form.Label>{pv["name"]}</Form.Label>
                                      <Select
                                        isMulti
                                        name="categories"
                                        options={
                                          pv["options"].length
                                            ? parseAttributeOptions(
                                                pv["options"]
                                              )
                                            : []
                                        }
                                        onChange={(e) => {
                                          const _selectedOptions = [];
                                          e.forEach((sv) => {
                                            _selectedOptions.push(sv["value"]);
                                          });
                                          filterOptions(
                                            index,
                                            _selectedOptions
                                          );
                                        }}
                                      />
                                    </Form.Group>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </>
                    ) : null}
                  </Col>
                  <Col md="8">
                    {filteredProductVariants.length ? (
                      <div style={{ maxHeight: "500px", overflow: "auto" }}>
                        <Table
                          className="mt-2"
                          bordered
                          responsive
                          width={"100%"}
                        >
                          <thead>
                            <tr>
                              <th>Product Variant</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredProductVariants.length
                              ? filteredProductVariants.map((pv) => {
                                  return (
                                    <tr>
                                      <td>{pv.variantName}</td>
                                    </tr>
                                  );
                                })
                              : null}
                          </tbody>
                        </Table>
                      </div>
                    ) : null}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default ViewProduct;
