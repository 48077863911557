import { useState, useEffect } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import { changePassword } from "../../services/profile";
import AppAlert from "../../components/AppAlert";
import { _swal } from "../../utils/helper";
function ChangePassword() {
  const [errorMessages, setErrorMessages] = useState([]);
  const onSubmit = (e) => {
    e.preventDefault();
    const formData = {
      old_password: e.target.old_password.value,
      new_password: e.target.new_password.value,
    };
    changePassword(formData)
      .then((response) => {
        if (response.data.status) {
          _swal(response.data.status, response.data.message);
        }
      })
      .catch((err) => {
        if (err.response.data.errors) {
          const _errorMessages = [];
          Object.entries(err.response.data.errors).forEach((e) => {
            _errorMessages.push(e[1]);
          });
          setErrorMessages([..._errorMessages]);
        }
        _swal(err.response.data.status, err.response.data.message);
      });
  };
  return (
    <Container className="my-5">
      <Row>
        <Col md="3"></Col>
        <Col md="6">
          <Card>
            <Card.Header as="h5">Change Password</Card.Header>
            <Card.Body>
              <Form onSubmit={onSubmit}>
                {errorMessages.length ? (
                  <AppAlert errors={errorMessages} />
                ) : (
                  null
                )}
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Old Password *</Form.Label>
                      <Form.Control
                        type="password"
                        name="old_password"
                        placeholder="Old Password"
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>New Password *</Form.Label>
                      <Form.Control
                        type="password"
                        name="new_password"
                        placeholder="New Password"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Button type="submit" variant="primary">
                  Change
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
        <Col md="3"></Col>
      </Row>
    </Container>
  );
}

export default ChangePassword;
