import { _APIHandler } from "../utils/helper";

let baseUrl = "products";
export const fetchProducts = (queryParams) => _APIHandler.get(`${baseUrl}`, { params: queryParams });
export const fetchProductLists = () => _APIHandler.get(`${baseUrl}/lists`);
export const fetchProduct = (id) => _APIHandler.get(`${baseUrl}/${id}`);
export const fetchDetailProduct = (id) => _APIHandler.get(`${baseUrl}/${id}/detail`);
export const createProduct = (data) => _APIHandler.post(`${baseUrl}`, data,);
export const updateProduct = (id, data) => _APIHandler.put(`${baseUrl}/${id}`, data);
export const deleteProduct = (id) => _APIHandler.delete(`${baseUrl}/${id}`);
export const cloneProduct = (id) => _APIHandler.get(`${baseUrl}/${id}/clone`);