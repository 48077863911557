import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Card,
  Form,
  Button,
  Table,
  Row,
  Col,
  Badge,
} from "react-bootstrap";
import {
  fetchProductProposals,
  updateProductProposalStatus,
  deleteProductProposal,
} from "../../services/productProposals";
import { _swal, decodeJWT } from "../../utils/helper";
function ProductProposalsList() {
  const jwtDecode = decodeJWT();
  const [productProposalsData, setProductProposalsData] = useState({});
  const searchSchema = {
    title: null,
    status: jwtDecode && jwtDecode.user_role === "A" ? "Pending" : null,
  };
  const [ProductProposalSearchValues, setProductProposalSearchValues] =
    useState(searchSchema);
  const _fetchProductProposals = async (queryParams = {}) => {
    await fetchProductProposals(queryParams)
      .then((response) => {
        const { data } = response;
        setProductProposalsData(data.data.product_proposals);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };
  const _deleteProductProposal = async (id) => {
    const _confirm = window.confirm("Are you sure to delete?");
    if (_confirm) {
      await deleteProductProposal(id)
        .then((response) => {
          const { data } = response;
          _swal(response.data.status, response.data.message);
          _fetchProductProposals();
        })
        .catch((err) => {
          _swal(err.response.data.status, err.response.data.message);
          console.log(err.response.data);
        });
    }
  };
  const _updateProductProposalStatus = async (id, status) => {
    const _confirm = window.confirm("Are you sure to update?");
    if (_confirm) {
      await updateProductProposalStatus(id, {
        status,
      })
        .then((response) => {
          const { data } = response;
          _swal(response.data.status, response.data.message);
          _fetchProductProposals({
            status: "Pending",
          });
        })
        .catch((err) => {
          _swal(err.response.data.status, err.response.data.message);
          console.log(err.response.data);
        });
    }
  };
  const resetSearch = () => {
    _fetchProductProposals({
      page: 1,
      per_page: 100,
      sort_column: "id",
      sort_order: "DESC",
    });
  };
  const filterProductProposal = (
    page = null,
    sort_column = null,
    sort_order = null
  ) => {
    const filteredKeys = {};
    Object.entries(ProductProposalSearchValues).filter((v) => {
      if (v[1] !== null) {
        filteredKeys[v[0]] = v[1];
      }
    });
    _fetchProductProposals({
      ...filteredKeys,
      page: page ? page : 1,
      per_page: 100,
      sort_column: sort_column ? sort_column : "id",
      sort_order: sort_order ? sort_order : "DESC",
    });
  };
  useEffect(() => {
    _fetchProductProposals({
      page: 1,
      per_page: 100,
      sort_column: "id",
      sort_order: "DESC",
    });
  }, []);
  return (
    <Container className="my-2">
      <Card>
        <Card.Header as="h5">
          Product Proposals{" "}
          {jwtDecode && jwtDecode.user_role === "S" ? (
            <Button
              variant="primary"
              as={Link}
              to={"/seller/product_proposals/create"}
              size="sm"
            >
              New
            </Button>
          ) : null}
        </Card.Header>
        <Card.Body>
          <Form>
            <Row>
              <Col md="3">
                <Form.Group className="mb-3">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    placeholder="Title"
                    value={
                      ProductProposalSearchValues.title !== null
                        ? ProductProposalSearchValues.title
                        : ""
                    }
                    onChange={(e) =>
                      setProductProposalSearchValues({
                        ...ProductProposalSearchValues,
                        title: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>

            <Button
              onClick={() => filterProductProposal()}
              variant="primary"
              className="mr-2"
            >
              Filter
            </Button>
            <Button
              onClick={(e) => {
                setProductProposalSearchValues({ ...searchSchema });
                resetSearch();
              }}
              variant="default"
            >
              Reset
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <Table bordered width={"100%"} className="mt-2">
        <thead>
          <tr>
            <th width="15%">Title</th>
            <th width="32.5%">Docs</th>
            <th width="32.5%">Description</th>
            <th width="10%" className="text-center">
              Status
            </th>
            <th width="10%" className="text-center">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {productProposalsData.length ? (
            productProposalsData.map((e) => {
              return (
                <tr>
                  <td>{e.title}</td>
                  <td>
                    {e.data_sheet_file &&
                      JSON.parse(e.data_sheet_file).map((fl) => {
                        return (
                          <a href={fl} target="_blank">
                            <Badge bg="info" className="m-1" text="dark">
                              {fl
                                .toString()
                                .replace(
                                  "https://eprocureglobal-prod.s3.ap-south-1.amazonaws.com/",
                                  ""
                                )}
                            </Badge>
                          </a>
                        );
                      })}
                  </td>
                  <td>
                    {e.description}
                    {jwtDecode && jwtDecode.user_role === "A" ? (
                      <>
                        <br />
                        <Button
                          variant="success"
                          size="sm"
                          onClick={() =>
                            _updateProductProposalStatus(e.id, "Created")
                          }
                        >
                          Mark as Created
                        </Button>{" "}
                        <Button
                          variant="warning"
                          size="sm"
                          onClick={() =>
                            _updateProductProposalStatus(e.id, "Rejected")
                          }
                        >
                          Mark as Rejected
                        </Button>
                      </>
                    ) : null}
                  </td>
                  <td className="text-center">{e.status}</td>
                  <td className="text-center">
                    {/* <Button
                      variant="default"
                      as={Link}
                      to={"/seller/product_proposals/" + e.id + "/edit"}
                      size="sm"
                    >
                      Edit
                    </Button> */}
                    {jwtDecode &&
                    jwtDecode.user_role === "S" &&
                    e.status === "Pending" ? (
                      <>
                        <Button
                          variant="warning"
                          size="sm"
                          onClick={() => _deleteProductProposal(e.id)}
                        >
                          Delete
                        </Button>
                      </>
                    ) : null}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={"3"}>Product Proposals not found</td>
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  );
}

export default ProductProposalsList;
