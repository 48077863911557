import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Container,
  Card,
  Form,
  Button,
  Table,
  Row,
  Col,
  Badge,
} from "react-bootstrap";
import {
  fetchPurchaseOrders,
  updatePOStatus,
} from "../../services/purchaseOrders";
import { _formatCurrencyPrice, _swal, decodeJWT } from "../../utils/helper";
const renderPOStatus = (status) => {
  switch (status) {
    case 0:
      return <Badge info="secondary">Pending</Badge>;
      break;
    case 1:
      return <Badge bg="success">Approved</Badge>;
      break;
    case 2:
      return <Badge bg="warning">Rejected</Badge>;
      break;
    default:
      return <Badge bg="secondary">Pending</Badge>;
      break;
  }
};
function PurchaseOrdersList() {
  const navigate = useNavigate();
  const jwtDecode = decodeJWT();
  const [purchaseOrdersData, setPurchaseOrdersData] = useState({});
  const searchSchema = {
    requested_date: null,
    pr_status: null,
  };
  const [PurchaseOrderSearchValues, setPurchaseOrderSearchValues] =
    useState(searchSchema);
  const _fetchPurchaseOrders = async (queryParams = {}) => {
    await fetchPurchaseOrders(queryParams)
      .then((response) => {
        const { data } = response;
        setPurchaseOrdersData(data.data.purchase_orders);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };
  const _updatePOStatus = async (id, status) => {
    await updatePOStatus(id, {
      status,
    })
      .then((response) => {
        const { data } = response;
        _swal(response.data.status, response.data.message);
        _fetchPurchaseOrders({
          page: 1,
          per_page: 100,
          sort_column: "id",
          sort_order: "DESC",
        });
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };
  const resetSearch = () => {
    _fetchPurchaseOrders({
      page: 1,
      per_page: 100,
      sort_column: "id",
      sort_order: "DESC",
    });
  };
  const filterPurchaseOrder = (
    page = null,
    sort_column = null,
    sort_order = null
  ) => {
    const filteredKeys = {};
    Object.entries(PurchaseOrderSearchValues).filter((v) => {
      if (v[1] !== null) {
        filteredKeys[v[0]] = v[1];
      }
    });
    _fetchPurchaseOrders({
      ...filteredKeys,
      page: page ? page : 1,
      per_page: 100,
      sort_column: sort_column ? sort_column : "id",
      sort_order: sort_order ? sort_order : "DESC",
    });
  };
  useEffect(() => {
    _fetchPurchaseOrders({
      page: 1,
      per_page: 100,
      sort_column: "id",
      sort_order: "DESC",
    });
  }, []);
  return (
    <Container className="my-2">
      <Card>
        <Card.Header as="h5">My Orders</Card.Header>
        <Card.Body>
          <Form>
            <Row>
              <Col md="3">
                <Form.Group className="mb-3">
                  <Form.Label>Order Type</Form.Label>
                  <Form.Select
                    name="order_type"
                    value={
                      PurchaseOrderSearchValues.order_type !== null
                        ? PurchaseOrderSearchValues.order_type
                        : ""
                    }
                    onChange={(e) =>
                      setPurchaseOrderSearchValues({
                        ...PurchaseOrderSearchValues,
                        order_type: e.target.value,
                      })
                    }
                  >
                    <option value=""></option>
                    <option value="RFQ">RFQ</option>
                    <option value="SP">Market Place</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            <Button
              onClick={() => filterPurchaseOrder()}
              variant="primary"
              className="mr-2"
            >
              Filter
            </Button>
            <Button
              onClick={(e) => {
                setPurchaseOrderSearchValues({ ...searchSchema });
                resetSearch();
              }}
              variant="default"
            >
              Reset
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <Table bordered width={"100%"} className="mt-2">
        <thead>
          <tr>
            <th width="5%">Order #</th>
            <th width="20%">Order Type</th>
            <th width="20%" style={{ textAlign: "right" }}>
              Tax
            </th>
            <th width="20%" style={{ textAlign: "right" }}>
              Total
            </th>
            <th width="20%" className="text-center">
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          {purchaseOrdersData.length ? (
            purchaseOrdersData.map((e) => {
              return (
                <tr>
                  <td>
                    #{e.order_number}
                    <br />
                    <Button
                      variant="secondary"
                      size="sm"
                      as={Link}
                      to={"/purchase_orders/" + e.id}
                    >
                      View
                    </Button>
                  </td>
                  <td>{e.order_type === "SP" ? "Market Place" : "RFQ"}</td>
                  <td style={{ textAlign: "right" }}>
                    {_formatCurrencyPrice(e.tax_total)}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {_formatCurrencyPrice(e.net_total)}
                  </td>
                  <td className="text-center">
                    {renderPOStatus(e.po_status)}
                    {jwtDecode &&
                    jwtDecode.user_role === "S" &&
                    e.po_status === 0 ? (
                      <div className="mt-2">
                        <Button
                          variant="success"
                          onClick={() => {
                            _updatePOStatus(e.id, "1");
                          }}
                          size="sm"
                        >
                          Approve
                        </Button>{" "}
                        <Button
                          variant="warning"
                          onClick={() => {
                            _updatePOStatus(e.id, "2");
                          }}
                          size="sm"
                        >
                          Reject
                        </Button>
                      </div>
                    ) : null}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={"3"}>Orders not found</td>
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  );
}
export default PurchaseOrdersList;
