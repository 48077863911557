import { Link } from "react-router-dom";
import { Card, Form, Button } from "react-bootstrap";
import { signin } from "../services/auth";
import { _swal, decodeJWT } from "../utils/helper";
function SignIn(props) {
  const { onHandleCurrentAuthModalForm } = props;
  const onSubmit = (e) => {
    e.preventDefault();
    const formData = {
      email: e.target.email.value,
      password: e.target.password.value,
    };
    signin(formData)
      .then((response) => {
        _swal(response.data.status, response.data.message);
        if (response.data.status) {
          localStorage.setItem("token", response.data.data.token);
          if (decodeJWT(response.data.data.token)["user_role"] === "A") {
            window.location.href = "/products";
          } else if (decodeJWT(response.data.data.token)["user_role"] === "S") {
            window.location.href = "/quotations";
          } else {
            window.location.href = "/purchase_requests";
          }
        }
      })
      .catch((err) => {
        _swal(err.response.data.status, err.response.data.message);
      });
  };
  return (
    <Card>
      <Card.Header as="h5">Sign in to your account</Card.Header>
      <Card.Body>
        <Form onSubmit={onSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Email address</Form.Label>
            <Form.Control type="email" name="email" placeholder="Enter email" />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Password </Form.Label>
            <Form.Control
              type="password"
              name="password"
              placeholder="Password"
            />
            <Form.Text muted>
              <Button
                variant="link"
                onClick={() => onHandleCurrentAuthModalForm("ForgotPassword")}
                className="p-0 m-0 ml-2"
              >
                Forgot Password?
              </Button>
            </Form.Text>
          </Form.Group>
          <div className="d-grid gap-2">
            <Button type="submit" variant="primary">
              Sign In
            </Button>
          </div>
        </Form>
        <p className="mt-4">
          Don't have an account?{" "}
          <Button
            variant="dark"
            onClick={() => onHandleCurrentAuthModalForm("SignUp")}
            size="sm"
          >
            Sign Up
          </Button>
        </p>
      </Card.Body>
    </Card>
  );
}

export default SignIn;
