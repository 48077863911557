import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Card, Form, Button, Row, Col } from "react-bootstrap";
import { fetchApprovedCompany, updateApprovedCompany } from "../../services/approvedCompanies";
import AppAlert from "../../components/AppAlert";
import { _swal } from "../../utils/helper";
function EditApprovedCompany() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [errorMessages, setErrorMessages] = useState([]);
  const [approvedCompanyData, setApprovedCompanyData] = useState({});
  const _fetchApprovedCompany = async (id) => {
    await fetchApprovedCompany(id)
      .then((response) => {
        const { data } = response;
        setApprovedCompanyData(data.data.approved_company);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    const formData = {
      name: e.target.name.value,
      description: e.target.description.value,
    };
    updateApprovedCompany(id, formData)
      .then((response) => {
        if (response.data.status) {
          _swal(response.data.status, response.data.message);
          navigate("/admin/approved_companies");
        }
      })
      .catch((err) => {
        if (err.response.data.errors) {
          const _errorMessages = [];
          Object.entries(err.response.data.errors).forEach((e) => {
            _errorMessages.push(e[1]);
          });
          setErrorMessages([..._errorMessages]);
        }
        _swal(err.response.data.status, err.response.data.message);
      });
  };
  useEffect(() => {
    _fetchApprovedCompany(id);
  }, []);
  return (
    <Container className="my-2">
      <Card>
        <Card.Header as="h5">Edit ApprovedCompany</Card.Header>
        <Card.Body>
          <Form onSubmit={onSubmit}>
            {errorMessages.length ? <AppAlert errors={errorMessages} /> : null}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Name
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Approved Company Name"
                  defaultValue={approvedCompanyData ? approvedCompanyData.name : null}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Description
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="description"
                  defaultValue={approvedCompanyData ? approvedCompanyData.description : null}
                />
              </Col>
            </Form.Group>
            <Button type="submit" variant="primary">
              Submit
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default EditApprovedCompany;
