import { Container, Button, Row, Col } from "react-bootstrap";
import AppFooter from "../components/AppFooter";
export default function PrivacyPolicy() {
  return (
    <>
      <div style={{ background: "rgb(54, 147, 61)", color: "white" }}>
        <Container className="py-3">
          <h2>
            <strong>Privacy Policy</strong>
          </h2>
        </Container>
      </div>
      <Container className="my-5">
        <p>
          This Privacy Policy (the “Policy”) applies to services offered by
          eproureglobal, its brands under the domain eprocureglobal.com By
          visiting this website you agree to be bound by the terms and
          conditions of this Policy. If you do not agree, please to not use or
          access our Site.
        </p>
      </Container>
      <AppFooter />
    </>
  );
}
