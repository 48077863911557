import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Container,
  Card,
  Form,
  Button,
  Table,
  Row,
  Col,
  Modal,
  Badge,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import { fetchUsers, deleteUser, updateUserStatus } from "../../services/users";
import { FaPencil, FaTrash } from "react-icons/fa6";
import { _swal } from "../../utils/helper";
const renderUserStatus = (status) => {
  switch (status) {
    case "Pending":
      return <Badge bg="info">{status}</Badge>;
    case "Approved":
      return <Badge bg="success">{status}</Badge>;
    case "Rejected":
      return <Badge bg="danger">{status}</Badge>;
    default:
      return <Badge bg="info">{status}</Badge>;
  }
};
function UsersList() {
  const [usersData, setUsersData] = useState({});
  const [userStatusData, setUserStatusData] = useState(null);
  const [userStatusModalShow, setuserStatusModalShow] = useState(false);
  const searchSchema = {
    first_name: null,
    last_name: null,
    email: null,
    company_name: null,
  };
  const [UserSearchValues, setUserSearchValues] = useState(searchSchema);
  const _fetchUsers = async (queryParams = {}) => {
    await fetchUsers(queryParams)
      .then((response) => {
        const { data } = response;
        setUsersData(data.data.users);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };
  const _deleteUser = async (id) => {
    const _confirm = window.confirm("Are you sure to delete?");
    if (_confirm) {
      await deleteUser(id)
        .then((response) => {
          const { data } = response;
          _swal(response.data.status, response.data.message);
          _fetchUsers();
        })
        .catch((err) => {
          _swal(err.response.data.status, err.response.data.message);
          console.log(err.response.data);
        });
    }
  };
  const resetSearch = () => {
    _fetchUsers({
      page: 1,
      per_page: 100,
      sort_column: "id",
      sort_order: "DESC",
    });
  };
  const filterUser = (page = null, sort_column = null, sort_order = null) => {
    const filteredKeys = {};
    Object.entries(UserSearchValues).filter((v) => {
      if (v[1] !== null) {
        filteredKeys[v[0]] = v[1];
      }
    });
    _fetchUsers({
      ...filteredKeys,
      page: page ? page : 1,
      per_page: 100,
      sort_column: sort_column ? sort_column : "id",
      sort_order: sort_order ? sort_order : "DESC",
    });
  };
  useEffect(() => {
    _fetchUsers({
      page: 1,
      per_page: 100,
      sort_column: "id",
      sort_order: "DESC",
    });
  }, []);
  return (
    <Container className="my-2">
      <Card>
        <Card.Header as="h5">
          Users{" "}
          <Button
            variant="primary"
            as={Link}
            to={"/admin/users/create"}
            size="sm"
          >
            New
          </Button>
        </Card.Header>
        <Card.Body>
          <Form>
            <Row>
              <Col md="3">
                <Form.Group className="mb-3">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="first_name"
                    placeholder="First Name"
                    value={
                      UserSearchValues.first_name !== null
                        ? UserSearchValues.first_name
                        : ""
                    }
                    onChange={(e) =>
                      setUserSearchValues({
                        ...UserSearchValues,
                        first_name: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>
              <Col md="3">
                <Form.Group className="mb-3">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="last_name"
                    placeholder="Last Name"
                    value={
                      UserSearchValues.last_name !== null
                        ? UserSearchValues.last_name
                        : ""
                    }
                    onChange={(e) =>
                      setUserSearchValues({
                        ...UserSearchValues,
                        last_name: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>
              <Col md="3">
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    placeholder="Email"
                    value={
                      UserSearchValues.email !== null
                        ? UserSearchValues.email
                        : ""
                    }
                    onChange={(e) =>
                      setUserSearchValues({
                        ...UserSearchValues,
                        email: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>
              <Col md="3">
                <Form.Group className="mb-3">
                  <Form.Label>Company Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="company_name"
                    placeholder="Company Name"
                    value={
                      UserSearchValues.company_name !== null
                        ? UserSearchValues.company_name
                        : ""
                    }
                    onChange={(e) =>
                      setUserSearchValues({
                        ...UserSearchValues,
                        company_name: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>

            <Button
              onClick={() => filterUser()}
              variant="primary"
              className="mr-2"
            >
              Filter
            </Button>
            <Button
              onClick={(e) => {
                setUserSearchValues({ ...searchSchema });
                resetSearch();
              }}
              variant="default"
            >
              Reset
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <Table bordered width={"100%"} className="mt-2">
        <thead>
          <tr>
            <th width="15%">Name</th>
            <th width="15%">Email</th>
            <th width="15%">Phone Number</th>
            <th width="15%">City</th>
            <th width="15%" className="text-center">
              Status
            </th>
            <th width="10%" className="text-center">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {usersData.length ? (
            usersData.map((e) => {
              return (
                <tr>
                  <td>
                    {e.first_name} {e.last_name}
                  </td>
                  <td>{e.email}</td>
                  <td>{e.phone_number}</td>
                  <td>{e.city}</td>
                  <td className="text-center">{renderUserStatus(e.status)}</td>
                  <td className="text-center">
                    <DropdownButton title="Action" variant="default">
                      {e.status === "Pending" ? (
                        <>
                          <Dropdown.Item
                            onClick={() => {
                              setUserStatusData({
                                id: e.id,
                                email: e.email,
                              });
                              setuserStatusModalShow(true);
                            }}
                          >
                            Update Status
                          </Dropdown.Item>
                          <Dropdown.Divider />
                        </>
                      ) : null}
                      <Dropdown.Item
                        as={Link}
                        to={"/admin/Users/" + e.id + "/edit"}
                      >
                        <FaPencil /> Edit
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => _deleteUser(e.id)}>
                        <FaTrash /> Delete
                      </Dropdown.Item>
                    </DropdownButton>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={"3"}>Users not found</td>
            </tr>
          )}
        </tbody>
      </Table>
      {userStatusData && userStatusModalShow ? (
        <UserStatusUpdateModal
          userData={userStatusData}
          onCloseUserStatusModal={setuserStatusModalShow}
        />
      ) : null}
    </Container>
  );
}

function UserStatusUpdateModal(props) {
  const navigate = useNavigate();
  const { userData, onCloseUserStatusModal } = props;
  const [show, setShow] = useState(false);
  const onSubmit = (e) => {
    e.preventDefault();
    const formData = {
      status: e.target.status.value,
      remarks: e.target.remarks.value,
    };
    updateUserStatus(userData.id, formData)
      .then((response) => {
        if (response.data.status) {
          _swal(response.data.status, response.data.message);
          onCloseUserStatusModal(false);
          window.location.href = "/admin/users";
        }
      })
      .catch((err) => {
        _swal(err.response.data.status, err.response.data.message);
      });
  };

  useEffect(() => {
    if (Object.entries(userData)) {
      setShow(true);
    }
  }, []);
  return (
    <>
      <Modal show={show} onHide={() => onCloseUserStatusModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title as="h5">
            Approve/Reject Status for {userData.email}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={onSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Status *</Form.Label>
              <Form.Select name="status">
                <option>Approved</option>
                <option>Rejected</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Remarks *</Form.Label>
              <Form.Control as="textarea" name="remarks" />
            </Form.Group>
            <Button type="submit" variant="primary">
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default UsersList;
