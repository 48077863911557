import { useState, useEffect } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import Select from "react-select";
import {
  fetchApprovedCompanyLists,
  fetchUserApprovedCompanies,
  updateUserApprovedCompany,
} from "../../services/approvedCompanies";
import AppAlert from "../../components/AppAlert";
import { _swal } from "../../utils/helper";
function MyApprovedCompanies() {
  const [errorMessages, setErrorMessages] = useState([]);
  const [approvedCompanyListOptions, setApprovedCompanyListOptions] = useState([]);
  const [selectedApprovedCompanies, setSelectedApprovedCompanies] = useState([]);
  const [defaultOptions, setDefaultOptions] = useState([]);
  const _fetchApprovedCompanyLists = async (queryParams = {}) => {
    await fetchApprovedCompanyLists(queryParams)
      .then((response) => {
        const { data } = response;
        const _options = [];
        data.data.approved_companies.forEach((v) => {
          _options.push({
            value: v["id"],
            label: v["name"],
          });
        });
        setApprovedCompanyListOptions(_options);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };
  const _fetchUserApprovedCompanies = async () => {
    await fetchUserApprovedCompanies()
      .then((response) => {
        const { data } = response;
        const _options = [];
        data.data.approved_companies.forEach((v) => {
          _options.push({
            value: v["approved_company_id"],
            label: v["ApprovedCompany"]["name"],
          });
        });
        setDefaultOptions(_options);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    updateUserApprovedCompany({
      approvedCompanyIds: selectedApprovedCompanies,
    })
      .then((response) => {
        if (response.data.status) {
          _swal(response.data.status, response.data.message);
        }
      })
      .catch((err) => {
        if (err.response.data.errors) {
          const _errorMessages = [];
          Object.entries(err.response.data.errors).forEach((e) => {
            _errorMessages.push(e[1]);
          });
          setErrorMessages([..._errorMessages]);
        }
        _swal(err.response.data.status, err.response.data.message);
      });
  };
  useEffect(() => {
    _fetchApprovedCompanyLists();
    _fetchUserApprovedCompanies();
  }, []);
  return (
    <Container className="my-5">
      <Row>
        <Col md="3"></Col>
        <Col md="6">
          <Card>
            <Card.Header as="h5">My Approved Companies</Card.Header>
            <Card.Body>
              <Form onSubmit={onSubmit}>
                {errorMessages.length ? (
                  <AppAlert errors={errorMessages} />
                ) : null}
                <Form.Group className="mb-3">
                  <Form.Label>Approved Companies *</Form.Label>
                  <Select
                    isMulti
                    name="approved_companies"
                    options={approvedCompanyListOptions}
                    value={defaultOptions}
                    onChange={(e) => {
                      const _selectedOptions = [];
                      const _selectedOptions2 = [];
                      e.forEach((sv) => {
                        _selectedOptions.push(sv["value"]);
                        _selectedOptions2.push({
                          value: sv["value"],
                          label: sv["label"],
                        });
                      });
                      setSelectedApprovedCompanies(_selectedOptions);
                      setDefaultOptions(_selectedOptions2);
                    }}
                  />
                </Form.Group>
                <Button type="submit" variant="primary">
                  Submit
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
        <Col md="3"></Col>
      </Row>
    </Container>
  );
}

export default MyApprovedCompanies;
