import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Card, Form, Button, Row, Col } from "react-bootstrap";
import { createWarehouse } from "../../services/warehouses";
import AppAlert from "../../components/AppAlert";
import { _swal } from "../../utils/helper";
import CountrySelect from "../../components/Input/CountrySelect";
function CreateWarehouse() {
  const navigate = useNavigate();
  const [errorMessages, setErrorMessages] = useState([]);
  const onSubmit = (e) => {
    e.preventDefault();
    const formData = {
      name: e.target.name.value,
      address_1: e.target.address_1.value,
      address_2: e.target.address_2.value,
      city: e.target.city.value,
      country_id: e.target.country_id.value,
      zip_code: e.target.zip_code.value,
      phone_number: e.target.phone_number.value,
      status: e.target.status.value,
    };
    createWarehouse(formData)
      .then((response) => {
        if (response.data.status) {
          _swal(response.data.status, response.data.message);
          navigate("/seller/warehouses");
        }
      })
      .catch((err) => {
        if (err.response.data.errors) {
          const _errorMessages = [];
          Object.entries(err.response.data.errors).forEach((e) => {
            _errorMessages.push(e[1]);
          });
          setErrorMessages([..._errorMessages]);
        }
        _swal(err.response.data.status, err.response.data.message);
      });
  };
  return (
    <Container className="my-2">
      <Card>
        <Card.Header as="h5">Create Supplier Location</Card.Header>
        <Card.Body>
          <Form onSubmit={onSubmit}>
            {errorMessages.length ? <AppAlert errors={errorMessages} /> : null}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Supplier Location Name
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Supplier Location Name"
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Address 1
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="text"
                  name="address_1"
                  placeholder="Address 1"
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Address 2
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="text"
                  name="address_2"
                  placeholder="Address 2"
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                City
              </Form.Label>
              <Col sm="4">
                <Form.Control type="text" name="city" placeholder="City" />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Country
              </Form.Label>
              <Col sm="4">
                <CountrySelect />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Zip Code
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="text"
                  name="zip_code"
                  placeholder="Zip Code"
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Phone Number
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="text"
                  name="phone_number"
                  placeholder="Phone Number"
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Status
              </Form.Label>
              <Col sm="4">
                <Form.Select name="status">
                  <option>Active</option>
                  <option>In-Active</option>
                </Form.Select>
              </Col>
            </Form.Group>
            <Button type="submit" variant="primary">
              Submit
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default CreateWarehouse;
