import { Link, useNavigate } from "react-router-dom";
import {
  Badge,
  Button,
  Container,
  Nav,
  Navbar,
  NavDropdown,
} from "react-bootstrap";
import { decodeJWT } from "../utils/helper";
function AppNavbar(props) {
  const { onHandleAuthModalClose, onHandleCurrentAuthModalForm } = props;
  const navigate = useNavigate();
  const jwtDecode = decodeJWT();
  const logout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };
  return (
    <Navbar
      expand="lg"
      className="bg-body-tertiary py-0"
      style={{ fontWeight: "bold", fontSize: "18px" }}
    >
      <Container>
        <Navbar.Brand as={Link} to={"/"}>
          <img
            src={"/logo.png"}
            width={"140px"}
            className="d-inline-block align-top"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse>
          <Nav className="me-auto">
            <Nav.Link as={Link} to={"/"}>
              Home
            </Nav.Link>
            {jwtDecode && jwtDecode.user_role === "B" ? (
              <Nav.Link as={Link} to={"/purchase_orders/create"}>
                Marketplace
              </Nav.Link>
            ) : null}
            {!jwtDecode ? (
              <>
                <Nav.Link as={Link} to={"/about_us"}>
                  About Us
                </Nav.Link>
                <Nav.Link as={Link} to={"/contact_us"}>
                  Contact Us
                </Nav.Link>
              </>
            ) : null}
            {jwtDecode && jwtDecode.user_role === "A" ? (
              <>
                <Nav.Link as={Link} to="/admin/products">
                  Products
                </Nav.Link>
                <NavDropdown title="Settings">
                  <NavDropdown.Item as={Link} to="/admin/users">
                    Users
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/admin/categories">
                    Categories
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/admin/industries">
                    Industries
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/admin/approved_companies">
                    Approved Companies
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/admin/documents">
                    User Documents
                  </NavDropdown.Item>
                </NavDropdown>
              </>
            ) : null}
            {jwtDecode && jwtDecode.user_role === "B" ? (
              <>
                <Nav.Link as={Link} to="/purchase_requests">
                  Purchase Requests
                </Nav.Link>
                <Nav.Link as={Link} to="/purchase_orders">
                  My Orders
                </Nav.Link>
              </>
            ) : null}
            {jwtDecode && jwtDecode.user_role === "S" ? (
              <>
                <Nav.Link as={Link} to="/seller/products">
                  My Products
                </Nav.Link>
                <Nav.Link as={Link} to="/seller/quotations">
                  Quotations
                </Nav.Link>
                <Nav.Link as={Link} to="/purchase_orders">
                  My Orders
                </Nav.Link>
              </>
            ) : null}
            {jwtDecode && jwtDecode.user_role === "A" ? (
              <>
                <Nav.Link as={Link} to="/admin/product_proposals">
                  Proposal Requests
                </Nav.Link>
              </>
            ) : null}
          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse className="justify-content-end">
          {jwtDecode ? (
            <>
              <NavDropdown title={"Hi, " + jwtDecode.first_name}>
                <NavDropdown.Item as={Link} to="/profile">
                  Profile
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/profile/password/change">
                  Change Password
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={Link} to={"/privacy_policy"}>
                  Privacy Policy
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to={"/terms_conditions"}>
                  Terms and Conditions
                </NavDropdown.Item>
                {jwtDecode && jwtDecode.user_role === "S" ? (
                  <>
                    <NavDropdown.Divider />
                    <NavDropdown.Item as={Link} to="/seller/warehouses">
                      Supplier Locations
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/seller/documents">
                      My Documents
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/seller/categories">
                      My Categories
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/seller/approved_companies">
                      My Approved Companies
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/seller/product_proposals">
                      Product Proposals
                    </NavDropdown.Item>
                  </>
                ) : null}
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={() => logout()}>
                  Log Out
                </NavDropdown.Item>
              </NavDropdown>
            </>
          ) : (
            <Nav.Link
              onClick={() => {
                onHandleAuthModalClose(true);
                onHandleCurrentAuthModalForm("SignIn");
              }}
            >
              Sign In
            </Nav.Link>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default AppNavbar;
