import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Card,
  Form,
  Button,
  Row,
  Col,
  Table,
} from "react-bootstrap";
import { fetchCategoryLists } from "../../services/categories";
import { createPurchaseRequest } from "../../services/purchaseRequests";
import AppAlert from "../../components/AppAlert";
import { _swal, _uuid } from "../../utils/helper";
function CreatePurchaseRequest() {
  const navigate = useNavigate();
  const [categoryListData, setCategoryListData] = useState([]);
  const [itemsList, setItemsList] = useState({});
  const [errorMessages, setErrorMessages] = useState([]);
  const _fetchCategoryLists = async () => {
    await fetchCategoryLists()
      .then((response) => {
        const { data } = response;
        setCategoryListData(data.data.categories);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };
  const addItem = () => {
    const _itemsList = { ...itemsList };
    _itemsList[_uuid()] = {
      category_id: null,
      product_description: null,
      quantity: 1,
    };
    setItemsList(_itemsList);
  };

  const removeItem = (id) => {
    const _itemsList = { ...itemsList };
    delete _itemsList[id];
    setItemsList(_itemsList);
  };

  const updateLineItem = (target, key, value) => {
    const updatedItemList = { ...itemsList };
    updatedItemList[target][key] = value;
    setItemsList(updatedItemList);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    const _items = [];
    Object.entries(itemsList).forEach((v) => {
      _items.push(v[1]);
    });
    const formData = {
      description: e.target.description.value,
      items: _items,
    };
    createPurchaseRequest(formData)
      .then((response) => {
        if (response.data.status) {
          _swal(response.data.status, response.data.message);
          navigate("/purchase_requests");
        }
      })
      .catch((err) => {
        if (err.response.data.errors) {
          const _errorMessages = [];
          Object.entries(err.response.data.errors).forEach((e) => {
            _errorMessages.push(e[1]);
          });
          setErrorMessages([..._errorMessages]);
        }
        _swal(err.response.data.status, err.response.data.message);
      });
  };
  useEffect(() => {
    _fetchCategoryLists();
    if (Object.entries(itemsList).length === 0) {
      addItem();
    }
  }, []);
  return (
    <Container className="my-2">
      <Card>
        <Card.Header as="h5">Create Purchase Request</Card.Header>
        <Card.Body>
          <Form onSubmit={onSubmit}>
            {errorMessages.length ? <AppAlert errors={errorMessages} /> : null}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Description/Notes
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  as="textarea"
                  name="description"
                />
              </Col>
            </Form.Group>
            <Table>
              <thead>
                <tr>
                  <th width={"20%"}>Category</th>
                  <th width={"65%"}>Product Description</th>
                  <th width={"10%"}>Quantity</th>
                  <th width={"5%"}></th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(itemsList).length !== 0 &&
                  Object.entries(itemsList).map((iv) => {
                    return (
                      <tr>
                        <td>
                          <Form.Select
                            name={`category_id[${iv[0]}]`}
                            value={iv[1]["category_id"]}
                            onChange={(e) => {
                              updateLineItem(
                                iv[0],
                                "category_id",
                                e.target.value
                              );
                            }}
                          >
                            <option value=""></option>
                            {categoryListData.map((cv) => {
                              return (
                                <option value={cv["id"]}>{cv["name"]}</option>
                              );
                            })}
                          </Form.Select>
                        </td>
                        <td>
                          <Form.Control
                            as="textarea"
                            name={`product_description[${iv[0]}]`}
                            value={iv[1]["product_description"]}
                            onChange={(e) => {
                              updateLineItem(
                                iv[0],
                                "product_description",
                                e.target.value
                              );
                            }}
                          />
                        </td>
                        <td>
                          <Form.Control
                            textAlign={"right"}
                            type="number"
                            name={`quantity[${iv[0]}]`}
                            value={iv[1]["quantity"]}
                            onChange={(e) => {
                              updateLineItem(iv[0], "quantity", e.target.value);
                            }}
                          />
                        </td>
                        <td>
                          <Button
                            variant="warning"
                            size={"sm"}
                            onClick={() => removeItem(iv[0])}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            <Button variant="secondary" size={"sm"} onClick={() => addItem()}>
              Add Item
            </Button>
            <br />
            <br />
            <Button type="submit" variant="primary">
              Submit
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default CreatePurchaseRequest;
