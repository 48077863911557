import { Container, Row, Col } from "react-bootstrap";
import SignUp from "./SignUp";
function SignUpPage() {
  return (
    <Container className="my-5">
      <Row>
        <Col md="4"></Col>
        <Col md="4">
          <SignUp />
        </Col>
        <Col md="4"></Col>
      </Row>
    </Container>
  );
}

export default SignUpPage;
