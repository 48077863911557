import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Card,
  Form,
  Button,
  Table,
  Row,
  Col,
  Badge,
} from "react-bootstrap";
import { fetchPurchaseRequests } from "../../services/purchaseRequests";
const renderPRStatus = (status) => {
  switch (status) {
    case 1:
      return <Badge info="secondary">Open</Badge>;
      break;
    case 2:
      return <Badge bg="info">In-Progress</Badge>;
      break;
    case 3:
      return <Badge bg="success">Quote Received</Badge>;
      break;
    case 4:
      return <Badge bg="success">PO Raised</Badge>;
      break;
    default:
      return <Badge bg="info">Open</Badge>;
      break;
  }
};
function PurchaseRequestsList() {
  const [usersData, setPurchaseRequestsData] = useState({});
  const searchSchema = {
    requested_date: null,
    pr_status: null,
  };
  const [PurchaseRequestSearchValues, setPurchaseRequestSearchValues] =
    useState(searchSchema);
  const _fetchPurchaseRequests = async (queryParams = {}) => {
    await fetchPurchaseRequests(queryParams)
      .then((response) => {
        const { data } = response;
        setPurchaseRequestsData(data.data.purchase_requests);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };
  const resetSearch = () => {
    _fetchPurchaseRequests({
      page: 1,
      per_page: 100,
      sort_column: "id",
      sort_order: "DESC",
    });
  };
  const filterPurchaseRequest = (
    page = null,
    sort_column = null,
    sort_order = null
  ) => {
    const filteredKeys = {};
    Object.entries(PurchaseRequestSearchValues).filter((v) => {
      if (v[1] !== null) {
        filteredKeys[v[0]] = v[1];
      }
    });
    _fetchPurchaseRequests({
      ...filteredKeys,
      page: page ? page : 1,
      per_page: 100,
      sort_column: sort_column ? sort_column : "id",
      sort_order: sort_order ? sort_order : "DESC",
    });
  };
  useEffect(() => {
    _fetchPurchaseRequests({
      page: 1,
      per_page: 100,
      sort_column: "id",
      sort_order: "DESC",
    });
  }, []);
  return (
    <Container className="my-2">
      <Card>
        <Card.Header as="h5">
           Purchase Requests{" "}
          <Button
            variant="primary"
            as={Link}
            to={"/purchase_requests/create"}
            size="sm"
          >
            New
          </Button>
        </Card.Header>
        <Card.Body>
          <Form>
            <Row>
              <Col md="3">
                <Form.Group className="mb-3">
                  <Form.Label>Requested Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="requested_date"
                    value={
                      PurchaseRequestSearchValues.requested_date !== null
                        ? PurchaseRequestSearchValues.requested_date
                        : ""
                    }
                    onChange={(e) =>
                      setPurchaseRequestSearchValues({
                        ...PurchaseRequestSearchValues,
                        requested_date: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>

            <Button
              onClick={() => filterPurchaseRequest()}
              variant="primary"
              className="mr-2"
            >
              Filter
            </Button>
            <Button
              onClick={(e) => {
                setPurchaseRequestSearchValues({ ...searchSchema });
                resetSearch();
              }}
              variant="default"
            >
              Reset
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <Table bordered width={"100%"} className="mt-2">
        <thead>
          <tr>
            <th width="5%">PR#</th>
            <th width="40%">Description/Notes</th>
            <th width="15%" className="text-center">Requested Date</th>
            <th width="10%" className="text-center">
              Status
            </th>
            <th width="15%" className="text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          {usersData.length ? (
            usersData.map((e) => {
              return (
                <tr>
                  <td>#{e.id}</td>
                  <td>{e.description}</td>
                  <td className="text-center">{e.formatted_requested_date}</td>
                  <td className="text-center">{renderPRStatus(e.pr_status)}</td>
                  <td className="text-center">
                    {e.pr_status === 1 ? (
                      <Button
                        variant="warning"
                        as={Link}
                        to={"/purchase_requests/" + e["id"] + "/rfq"}
                        size="sm"
                      >
                        Request for Quotation
                      </Button>
                    ) : null}
                    {e.pr_status === 3 ? (
                      <Button
                        variant="info"
                        as={Link}
                        to={"/purchase_requests/" + e["id"] + "/compare"}
                        size="sm"
                      >
                        Compare Quote
                      </Button>
                    ) : null}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={"3"}>Purchase Requests not found</td>
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  );
}
export default PurchaseRequestsList;
