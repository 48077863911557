import { _APIHandler } from "../utils/helper";

let baseUrl = "categories";
export const fetchCategories = (queryParams) => _APIHandler.get(`${baseUrl}`, { params: queryParams });
export const fetchUserCategories = () => _APIHandler.get(`${baseUrl}/users`);
export const fetchCategoryLists = () => _APIHandler.get(`${baseUrl}/lists`);
export const fetchCategory = (id) => _APIHandler.get(`${baseUrl}/${id}`);
export const createCategory = (data) => _APIHandler.post(`${baseUrl}`, data,);
export const updateCategory = (id, data) => _APIHandler.put(`${baseUrl}/${id}`, data);
export const updateUserCategory = (data) => _APIHandler.put(`${baseUrl}/users`, data);
export const deleteCategory = (id) => _APIHandler.delete(`${baseUrl}/${id}`);