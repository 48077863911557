import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Card, Form, Button, Row, Col } from "react-bootstrap";
import { createIndustry } from "../../services/industries";
import AppAlert from "../../components/AppAlert";
import { _swal } from "../../utils/helper";
function CreateIndustry() {
  const navigate = useNavigate();
  const [errorMessages, setErrorMessages] = useState([]);
  const onSubmit = (e) => {
    e.preventDefault();
    const formData = {
      name: e.target.name.value,
      description: e.target.description.value,
    };
    createIndustry(formData)
      .then((response) => {
        if (response.data.status) {
          _swal(response.data.status, response.data.message);
          navigate("/admin/industries");
        }
      })
      .catch((err) => {
        if (err.response.data.errors) {
          const _errorMessages = [];
          Object.entries(err.response.data.errors).forEach((e) => {
            _errorMessages.push(e[1]);
          });
          setErrorMessages([..._errorMessages]);
        }
        _swal(err.response.data.status, err.response.data.message);
      });
  };
  return (
    <Container className="my-2">
      <Card>
        <Card.Header as="h5">Create Industry</Card.Header>
        <Card.Body>
          <Form onSubmit={onSubmit}>
            {errorMessages.length ? <AppAlert errors={errorMessages} /> : null}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Name
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Industry Name"
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Description
              </Form.Label>
              <Col sm="4">
                <Form.Control as="textarea" rows={3} name="description" />
              </Col>
            </Form.Group>
            <Button type="submit" variant="primary">
              Submit
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default CreateIndustry;
