import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Card,
  Form,
  Button,
  Table,
  Row,
  Col,
} from "react-bootstrap";
import { fetchCategories, deleteCategory } from "../../services/categories";
import { _swal } from "../../utils/helper";
function CategoriesList() {
  const [categoriesData, setCategoriesData] = useState({});
  const searchSchema = {
    name: null,
  };
  const [categorySearchValues, setCategorySearchValues] =
    useState(searchSchema);
  const _fetchCategories = async (queryParams = {}) => {
    await fetchCategories(queryParams)
      .then((response) => {
        const { data } = response;
        setCategoriesData(data.data.categories);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };
  const _deleteCategory = async (id) => {
    const _confirm = window.confirm("Are you sure to delete?");
    if (_confirm) {
      await deleteCategory(id)
        .then((response) => {
          const { data } = response;
          _swal(response.data.status, response.data.message);
          _fetchCategories();
        })
        .catch((err) => {
          _swal(err.response.data.status, err.response.data.message);
          console.log(err.response.data);
        });
    }
  };
  const resetSearch = () => {
    _fetchCategories({
      page: 1,
      per_page: 100,
      sort_column: "id",
      sort_order: "DESC",
    });
  };
  const filterCategory = (
    page = null,
    sort_column = null,
    sort_order = null
  ) => {
    const filteredKeys = {};
    Object.entries(categorySearchValues).filter((v) => {
      if (v[1] !== null) {
        filteredKeys[v[0]] = v[1];
      }
    });
    _fetchCategories({
      ...filteredKeys,
      page: page ? page : 1,
      per_page: 100,
      sort_column: sort_column ? sort_column : "id",
      sort_order: sort_order ? sort_order : "DESC",
    });
  };
  useEffect(() => {
    _fetchCategories({
      page: 1,
      per_page: 100,
      sort_column: "id",
      sort_order: "DESC",
    });
  }, []);
  return (
    <Container className="my-2">
      <Card>
        <Card.Header as="h5">
           Categories{" "}
          <Button
            variant="primary"
            as={Link}
            to={"/admin/categories/create"}
            size="sm"
          >
            New
          </Button>
        </Card.Header>
        <Card.Body>
          <Form>
            <Row>
              <Col md="4">
                <Form.Group className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="Category Name"
                    value={
                      categorySearchValues.name !== null
                        ? categorySearchValues.name
                        : ""
                    }
                    onChange={(e) =>
                      setCategorySearchValues({
                        ...categorySearchValues,
                        name: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>

            <Button
              onClick={() => filterCategory()}
              variant="primary"
              className="mr-2"
            >
              Filter
            </Button>
            <Button
              onClick={(e) => {
                setCategorySearchValues({ ...searchSchema });
                resetSearch();
              }}
              variant="default"
            >
              Reset
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <Table bordered width={"100%"} className="mt-2">
        <thead>
          <tr>
            <th width="25%">Category Name</th>
            <th width="65%">Description</th>
            <th width="10%">Action</th>
          </tr>
        </thead>
        <tbody>
          {categoriesData.length ? (
            categoriesData.map((e) => {
              return (
                <tr>
                  <td>{e.name}</td>
                  <td>{e.description}</td>
                  <td>
                    <Button
                      variant="default"
                      as={Link}
                      to={"/admin/categories/" + e.id + "/edit"}
                      size="sm"
                    >
                      Edit
                    </Button>
                    <Button
                      variant="warning"
                      size="sm"
                      onClick={() => _deleteCategory(e.id)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={"3"}>Categories not found</td>
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  );
}

export default CategoriesList;
