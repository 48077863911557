import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Container, Card, Button, Table, Badge } from "react-bootstrap";
import { fetchQuotationsComparison } from "../../services/quotations";
import { raiseRFQPurchaseOrder } from "../../services/purchaseOrders";
import { _formatCurrencyPrice, _swal } from "../../utils/helper";
function QuotationComparison() {
  const { id: purchaseRequestId } = useParams();
  const navigate = useNavigate();
  const [comparisonData, setComparisonData] = useState([]);
  const [selectedQuote, setSelectedQuote] = useState({});
  const [errorMessages, setErrorMessages] = useState([]);
  const _fetchQuotationsComparison = async (sellerId) => {
    await fetchQuotationsComparison(sellerId)
      .then((response) => {
        const { data } = response;
        setComparisonData(data.data.products);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };
  useEffect(() => {
    _fetchQuotationsComparison(purchaseRequestId);
  }, []);
  const selectItem = (quotationId, quotationItemId) => {
    const _selectedQuote = { ...selectedQuote };
    if (_selectedQuote[quotationId] === undefined) {
      _selectedQuote[quotationId] = [];
    }
    if (!_selectedQuote[quotationId].includes(quotationItemId)) {
      _selectedQuote[quotationId].push(quotationItemId);
    }
    setSelectedQuote({ ..._selectedQuote });
  };
  const _raisePO = () => {
    raiseRFQPurchaseOrder({
      quotations: selectedQuote,
    })
      .then((response) => {
        if (response.data.status) {
          _swal(response.data.status, response.data.message);
          navigate("/purchase_orders");
        }
      })
      .catch((err) => {
        if (err.response.data.errors) {
          const _errorMessages = [];
          Object.entries(err.response.data.errors).forEach((e) => {
            _errorMessages.push(e[1]);
          });
          setErrorMessages([..._errorMessages]);
        }
        _swal(err.response.data.status, err.response.data.message);
      });
  };
  return (
    <Container className="my-2">
      <Card>
        <Card.Header as="h5">
          Quotation Comparison #{purchaseRequestId}
        </Card.Header>
      </Card>
      <Table bordered width={"100%"} className="mt-2">
        <thead>
          <tr>
            <th>Quotation #</th>
            <th>Seller</th>
            <th style={{ textAlign: "center" }}>Quantity</th>
            <th style={{ textAlign: "right" }}>Unit Price</th>
            <th style={{ textAlign: "right" }}>Tax Rate</th>
            <th style={{ textAlign: "right" }}>Tax Amount</th>
            <th style={{ textAlign: "right" }}>Total Cost</th>
            <th style={{ textAlign: "center" }}>Delivery Date</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          {comparisonData &&
            Object.entries(comparisonData).map((e) => {
              return (
                <>
                  <tr bg={"aliceblue"}>
                    <td colSpan={"9"}>{e[0]}</td>
                  </tr>
                  {e[1].map((i) => {
                    return (
                      <tr>
                        <td>
                          Quotation-#{i["quotation_id"]}
                        </td>
                        <td>{i["seller_name"]}</td>
                        <td style={{ textAlign: "center" }}>{i["quantity"]}</td>
                        <td style={{ textAlign: "right" }}>
                          {_formatCurrencyPrice(i["unit_price"])}
                        </td>
                        <td style={{ textAlign: "right" }}>{i["tax_rate"]}%</td>
                        <td style={{ textAlign: "right" }}>
                          {_formatCurrencyPrice(i["tax_amount"])}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {_formatCurrencyPrice(i["total_amount"])}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {i["formatted_delivery_date"]}
                        </td>
                        <td className="text-center">
                          {selectedQuote[i["quotation_id"]] === undefined ||
                          (selectedQuote[i["quotation_id"]] &&
                            !selectedQuote[i["quotation_id"]].includes(
                              i["quotation_item_id"]
                            )) ? (
                            <Button
                              size="sm"
                              onClick={() =>
                                selectItem(
                                  i["quotation_id"],
                                  i["quotation_item_id"]
                                )
                              }
                            >
                              Select
                            </Button>
                          ) : (
                            <Badge bg="success">Selected</Badge>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </>
              );
            })}
        </tbody>
      </Table>
      {Object.entries(selectedQuote).length !== 0 ? (
        <Button colorScheme="blue" size="sm" onClick={() => _raisePO()}>
          Raise PO
        </Button>
      ) : null}
    </Container>
  );
}

export default QuotationComparison;
