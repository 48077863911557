import { Link } from "react-router-dom";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import { forgotPassword } from "../../services/auth";
import { _swal } from "../../utils/helper";
function ForgotPassword(props) {
  const { onHandleCurrentAuthModalForm } = props;
  const onSubmit = (e) => {
    e.preventDefault();
    const formData = {
      email: e.target.email.value,
    };
    forgotPassword(formData)
      .then((response) => {
        if (response.data.status) {
          _swal(response.data.status, response.data.message);
          e.target.reset();
        }
      })
      .catch((err) => {
        _swal(err.response.data.status, err.response.data.message);
      });
  };
  return (
    <Card>
      <Card.Header as="h5">Forgot Password</Card.Header>
      <Card.Body>
        <Form onSubmit={onSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Email address</Form.Label>
            <Form.Control type="email" name="email" placeholder="Enter email" />
          </Form.Group>
          <div className="d-grid gap-2">
            <Button type="submit" variant="primary">
              Submit
            </Button>
          </div>
        </Form>
        <p className="mt-4">
          Do you remember your password?{" "}
          <Button
            variant="dark"
            onClick={() => onHandleCurrentAuthModalForm("SignIn")}
            size="sm"
          >
            Sign In
          </Button>
        </p>
      </Card.Body>
    </Card>
  );
}

export default ForgotPassword;
