import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Card,
  Form,
  Button,
  Table,
  Row,
  Col,
  Badge
} from "react-bootstrap";
import {
  fetchSellerQuotations,
} from "../../services/quotations";
const renderQuoteStatus = (status) => {
  switch (status) {
    case 1:
      return <Badge bg="warning">Pending</Badge>;
      break;
    case 2:
      return <Badge bg="info">Quote Sent</Badge>;
      break;
    case 3:
      return <Badge bg="success">Quote Accepted</Badge>;
      break;
    default:
      return <Badge bg="warning">Pending</Badge>;
      break;
  }
};
function QuotationsList() {
  const [quotationsData, setQuotationsData] = useState([]);
  const searchSchema = {
    expected_date: null,
    quotation_date: null,
    quote_status: null,
  };
  const [QuotationSearchValues, setQuotationSearchValues] =
    useState(searchSchema);
  const _fetchSellerQuotations = async (queryParams = {}) => {
    await fetchSellerQuotations(queryParams)
      .then((response) => {
        const { data } = response;
        setQuotationsData(data.data.quotations);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };
  const resetSearch = () => {
    _fetchSellerQuotations({
      page: 1,
      per_page: 100,
      sort_column: "id",
      sort_order: "DESC",
    });
  };
  const filterQuotation = (
    page = null,
    sort_column = null,
    sort_order = null
  ) => {
    const filteredKeys = {};
    Object.entries(QuotationSearchValues).filter((v) => {
      if (v[1] !== null) {
        filteredKeys[v[0]] = v[1];
      }
    });
    _fetchSellerQuotations({
      ...filteredKeys,
      page: page ? page : 1,
      per_page: 100,
      sort_column: sort_column ? sort_column : "id",
      sort_order: sort_order ? sort_order : "DESC",
    });
  };
  useEffect(() => {
    _fetchSellerQuotations({
      page: 1,
      per_page: 100,
      sort_column: "id",
      sort_order: "DESC",
    });
  }, []);
  return (
    <Container className="my-2">
      <Card>
        <Card.Header as="h5">My Quotations</Card.Header>
        <Card.Body>
          <Form>
            <Row>
              <Col md="3">
                <Form.Group className="mb-3">
                  <Form.Label>Expeceted Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="expected_date"
                    value={
                      QuotationSearchValues.expected_date !== null
                        ? QuotationSearchValues.expected_date
                        : ""
                    }
                    onChange={(e) =>
                      setQuotationSearchValues({
                        ...QuotationSearchValues,
                        expected_date: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>
              <Col md="3">
                <Form.Group className="mb-3">
                  <Form.Label>Quotation Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="quotation_date"
                    value={
                      QuotationSearchValues.quotation_date !== null
                        ? QuotationSearchValues.quotation_date
                        : ""
                    }
                    onChange={(e) =>
                      setQuotationSearchValues({
                        ...QuotationSearchValues,
                        quotation_date: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>
              <Col md="3">
                <Form.Group className="mb-3">
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    type="text"
                    name="quote_status"
                    value={
                      QuotationSearchValues.quote_status !== null
                        ? QuotationSearchValues.quote_status
                        : ""
                    }
                    onChange={(e) =>
                      setQuotationSearchValues({
                        ...QuotationSearchValues,
                        quote_status: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>

            <Button
              onClick={() => filterQuotation()}
              variant="primary"
              className="mr-2"
            >
              Filter
            </Button>
            <Button
              onClick={(e) => {
                setQuotationSearchValues({ ...searchSchema });
                resetSearch();
              }}
              variant="default"
            >
              Reset
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <Table bordered width={"100%"} className="mt-2">
        <thead>
          <tr>
            <th>RFQ #</th>
            <th>RFQ Date</th>
            <th>Comments</th>
            <th>Expected Date</th>
            <th className="text-center">Status</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          {quotationsData.length ? (
            quotationsData.map((e) => {
              return (
                <tr>
                  <td>RFQ-#{e.id}</td>
                  <td>{e.formatted_expected_date}</td>
                  <td>{e.comments}</td>
                  <td>{e.formatted_quotation_date}</td>
                  <td className="text-center">{renderQuoteStatus(e.quote_status)}</td>
                  <td className="text-center">
                    {e.quote_status === 1 ? (
                      <Button
                        variant="info"
                        size="sm"
                        as={Link}
                        to={"/seller/quotations/" + e.id + "/send"}
                      >
                        Add Quotation
                      </Button>
                    ) : null}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={"3"}>Quotations not found</td>
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  );
}

export default QuotationsList;
