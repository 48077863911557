import { useState, useEffect } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import { fetchProfile, updateProfile } from "../../services/profile";
import AppAlert from "../../components/AppAlert";
import { _swal } from "../../utils/helper";
import CountrySelect from "../../components/Input/CountrySelect";
function EditProfile() {
  const [profileData, setProfileData] = useState({});
  const [errorMessages, setErrorMessages] = useState([]);
  const _fetchProfile = async () => {
    await fetchProfile()
      .then((response) => {
        const { data } = response;
        setProfileData(data.data.user);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    const formData = {
      first_name: e.target.first_name.value,
      last_name: e.target.last_name.value,
      company_name: e.target.company_name.value,
      address_1: e.target.address_1.value,
      address_2: e.target.address_2.value,
      city: e.target.city.value,
      country_id: e.target.country_id.value,
      zip_code: e.target.zip_code.value,
      phone_number: e.target.phone_number.value,
    };
    updateProfile(formData)
      .then((response) => {
        if (response.data.status) {
          _swal(response.data.status, response.data.message);
        }
      })
      .catch((err) => {
        if (err.response.data.errors) {
          const _errorMessages = [];
          Object.entries(err.response.data.errors).forEach((e) => {
            _errorMessages.push(e[1]);
          });
          setErrorMessages([..._errorMessages]);
        }
        _swal(err.response.data.status, err.response.data.message);
      });
  };
  useEffect(() => {
    _fetchProfile();
  }, []);
  return (
    <Container className="my-5">
      <Row>
        <Col md="3"></Col>
        <Col md="6">
          <Card>
            <Card.Header as="h5">Profile</Card.Header>
            <Card.Body>
              <Form onSubmit={onSubmit}>
                {errorMessages.length ? (
                  <AppAlert errors={errorMessages} />
                ) : null}
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>First Name *</Form.Label>
                      <Form.Control
                        type="text"
                        name="first_name"
                        placeholder="First Name"
                        defaultValue={
                          profileData ? profileData.first_name : null
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Last Name *</Form.Label>
                      <Form.Control
                        type="text"
                        name="last_name"
                        placeholder="Last Name"
                        defaultValue={
                          profileData ? profileData.last_name : null
                        }
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group className="mb-3">
                  <Form.Label>Email *</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Email"
                    defaultValue={profileData ? profileData.email : null}
                    readOnly
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    placeholder="Password"
                    defaultValue={profileData ? profileData.password : null}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Company Name *</Form.Label>
                  <Form.Control
                    type="text"
                    name="company_name"
                    placeholder="Company Name"
                    defaultValue={profileData ? profileData.company_name : null}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Address 1 *</Form.Label>
                  <Form.Control
                    type="text"
                    name="address_1"
                    placeholder="Address 1"
                    defaultValue={profileData ? profileData.address_1 : null}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Address 2</Form.Label>
                  <Form.Control
                    type="text"
                    name="address_2"
                    placeholder="Address 2"
                    defaultValue={profileData ? profileData.address_2 : null}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>City *</Form.Label>
                  <Form.Control
                    type="text"
                    name="city"
                    placeholder="City"
                    defaultValue={profileData ? profileData.city : null}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Country *</Form.Label>
                  {profileData && profileData.country_id ? (
                    <CountrySelect selectedValues={[profileData.country_id]} />
                  ) : null}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Zip Code *</Form.Label>
                  <Form.Control
                    type="text"
                    name="zip_code"
                    placeholder="Zip Code"
                    defaultValue={profileData ? profileData.zip_code : null}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Phone Number *</Form.Label>
                  <Form.Control
                    type="text"
                    name="phone_number"
                    placeholder="Phone Number"
                    defaultValue={profileData ? profileData.phone_number : null}
                  />
                </Form.Group>
                <Button type="submit" variant="primary">
                  Save
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
        <Col md="3"></Col>
      </Row>
    </Container>
  );
}

export default EditProfile;
