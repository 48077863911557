import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Container,
  Card,
  Form,
  Button,
  Row,
  Col,
  Table,
} from "react-bootstrap";
import Select from "react-select";
import { fetchApprovedCompanies } from "../../services/approvedCompanies";
import { fetchPurchaseRequest } from "../../services/purchaseRequests";
import { createRFQ } from "../../services/quotations";
import AppAlert from "../../components/AppAlert";
import { _swal } from "../../utils/helper";
function RequestForQuotation() {
  const { id: purchaseRequestId } = useParams();
  const navigate = useNavigate();
  const [purchaseRequestData, setPurchaseRequestData] = useState([]);
  const [approvedCompanyOptions, setApprovedCompanyOptions] = useState([]);
  const [selectedApprovedCompanies, setSelectedApprovedCompanies] = useState(
    []
  );
  const [errorMessages, setErrorMessages] = useState([]);
  const _fetchApprovedCompanies = async () => {
    await fetchApprovedCompanies()
      .then((response) => {
        const { data } = response;
        const _options = [];
        data.data.approved_companies.forEach((v) => {
          _options.push({
            value: v["id"],
            label: v["name"],
          });
        });
        setApprovedCompanyOptions(_options);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };
  const _fetchPurchaseRequest = async (purchaseRequestId) => {
    await fetchPurchaseRequest(purchaseRequestId)
      .then((response) => {
        const { data } = response;
        setPurchaseRequestData(data.data.purchase_request);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    const formData = {
      purchase_request_id: purchaseRequestId,
      expected_date: e.target.expected_date.value,
      comments: e.target.comments.value,
      approvedCompanies: selectedApprovedCompanies,
    };
    createRFQ(formData)
      .then((response) => {
        if (response.data.status) {
          _swal(response.data.status, response.data.message);
          navigate("/purchase_requests");
        }
      })
      .catch((err) => {
        if (err.response.data.errors) {
          const _errorMessages = [];
          Object.entries(err.response.data.errors).forEach((e) => {
            _errorMessages.push(e[1]);
          });
          setErrorMessages([..._errorMessages]);
        }
        _swal(err.response.data.status, err.response.data.message);
      });
  };
  useEffect(() => {
    _fetchApprovedCompanies();
    _fetchPurchaseRequest(purchaseRequestId);
  }, []);
  return (
    <Container className="my-2">
      <Card>
        <Card.Header as="h5">
          Request Quotation for #{purchaseRequestId}
        </Card.Header>
        <Card.Body>
          <Form onSubmit={onSubmit}>
            {errorMessages.length ? <AppAlert errors={errorMessages} /> : null}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Expeceted Date
              </Form.Label>
              <Col sm="4">
                <Form.Control type="date" name="expected_date" />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Comments
              </Form.Label>
              <Col sm="4">
                <Form.Control as="textarea" name="comments" />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Approved Companies
              </Form.Label>
              <Col sm="4">
                <Select
                  isMulti
                  name="approvedCompanies"
                  options={approvedCompanyOptions}
                  onChange={(e) => {
                    const _selectedOptions = [];
                    const _selectedOptions2 = [];
                    e.forEach((sv) => {
                      _selectedOptions.push(sv["value"]);
                      _selectedOptions2.push({
                        value: sv["value"],
                        label: sv["label"],
                      });
                    });
                    setSelectedApprovedCompanies(_selectedOptions);
                  }}
                />
              </Col>
            </Form.Group>
            <h5>Requested Items</h5>
            <Table bordered>
              <thead>
                <tr>
                  <th width={"70%"}>Product</th>
                  <th width={"30%"}>Quantity</th>
                </tr>
              </thead>
              <tbody>
                {purchaseRequestData &&
                  purchaseRequestData.purchase_request_items &&
                  purchaseRequestData.purchase_request_items.map((pr) => {
                    return (
                      <tr>
                        <td>{pr.product_description}</td>
                        <td>{pr.quantity}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            <Button type="submit" variant="primary">
              Submit
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default RequestForQuotation;
