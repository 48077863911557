import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Card,
  Form,
  Button,
  Table,
  Row,
  Col,
  Badge,
} from "react-bootstrap";
import { fetchPurchaseOrder } from "../../services/purchaseOrders";
import { _formatCurrencyPrice, _units, decodeJWT } from "../../utils/helper";
const renderPOStatus = (status) => {
  switch (status) {
    case 0:
      return <Badge info="secondary">Pending</Badge>;
      break;
    case 1:
      return (
        <Badge bg="success" text="white">
          Approved
        </Badge>
      );
      break;
    case 2:
      return <Badge bg="warning">Rejected</Badge>;
      break;
    default:
      return <Badge bg="secondary">Pending</Badge>;
      break;
  }
};
function ViewPurchaseOrder() {
  const jwtDecode = decodeJWT();
  const { id } = useParams();
  const [purchaseOrderData, setPurchaseOrderData] = useState({});
  const [netTotal, setNetTotal] = useState(0.0);
  const [sellerDetailData, setSellerDetailData] = useState({});
  const [buyerDetailData, setBuyerDetailData] = useState({});
  const _fetchPurchaseOrder = (id) => {
    fetchPurchaseOrder(id)
      .then((response) => {
        const { data } = response;
        setPurchaseOrderData(data.data.purchase_order);
        if (data.data.purchase_order) {
          let _netTotal = 0;
          data.data.purchase_order.purchase_order_items.forEach((e) => {
            _netTotal += parseFloat(e["total_amount"]);
          });
          setNetTotal(_netTotal);
        }
        setSellerDetailData(data.data.seller_detail);
        setBuyerDetailData(data.data.buyer_detail);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };
  useEffect(() => {
    _fetchPurchaseOrder(id);
  }, []);
  return (
    <Container className="my-2">
      <Card>
        <Card.Header as="h5">
          Order #{purchaseOrderData.order_number}
        </Card.Header>
        <Card.Body>
          <Row>
            <Col md="6">
              <Table>
                {/* <tr>
                  <td>Created Time</td>
                  <td>{purchaseOrderData.created_at}</td>
                </tr> */}
                <tr>
                  <td>Order Type</td>
                  <td>{purchaseOrderData.order_type}</td>
                </tr>
                <tr>
                  <td>Quotation #</td>
                  <td>{purchaseOrderData.quotation_id}</td>
                </tr>
                <tr>
                  <td>Order Status</td>
                  <td>{renderPOStatus(purchaseOrderData.po_status)}</td>
                </tr>
                {jwtDecode && jwtDecode["user_role"] === "B" ? (
                  <tr>
                    <td>Seller Details</td>
                    <td>
                      {purchaseOrderData.po_status === 1 ? (
                        <>
                          Seller Name : {sellerDetailData.first_name}{" "}
                          {sellerDetailData.last_name}
                          <br />
                          Contact Email : {sellerDetailData.email}
                        </>
                      ) : (
                        "TBA"
                      )}
                    </td>
                  </tr>
                ) : null}
                {jwtDecode && jwtDecode["user_role"] === "S" ? (
                  <tr>
                    <td>Buyer Details</td>
                    <td>
                      {purchaseOrderData.po_status === 1 ? (
                        <>
                          Buyer Name : {buyerDetailData.first_name}{" "}
                          {buyerDetailData.last_name}
                          <br />
                          Contact Email : {buyerDetailData.email}
                        </>
                      ) : (
                        "TBA"
                      )}
                    </td>
                  </tr>
                ) : null}
              </Table>
            </Col>
          </Row>
          <Table bordered>
            <thead>
              <tr>
                <td>Product Description</td>
                <td style={{ textAlign: "right" }}>Quantity</td>
                <td style={{ textAlign: "right" }}>Unit Price</td>
                <td style={{ textAlign: "right" }}>Tax</td>
                <td style={{ textAlign: "right" }}>Total Amount</td>
              </tr>
            </thead>
            <tbody>
              {purchaseOrderData &&
                purchaseOrderData.purchase_order_items &&
                purchaseOrderData.purchase_order_items.map((e) => {
                  return (
                    <tr>
                      <td>{e.product_description}</td>
                      <td style={{ textAlign: "right" }}>
                        {e.quantity} {_units(e.unit_measurement)}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {_formatCurrencyPrice(e.unit_price)}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {_formatCurrencyPrice(e.tax_amount)}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {_formatCurrencyPrice(e.total_amount)}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
            <tfoot>
              <tr style={{ fontWeight: "bold" }}>
                <td style={{ textAlign: "right" }} colSpan={"4"}>
                  Sub Total
                </td>
                <td style={{ textAlign: "right" }}>
                  {_formatCurrencyPrice(
                    purchaseOrderData.net_total - purchaseOrderData.tax_total
                  )}
                </td>
              </tr>
              <tr style={{ fontWeight: "bold" }}>
                <td style={{ textAlign: "right" }} colSpan={"4"}>
                  Tax
                </td>
                <td style={{ textAlign: "right" }}>
                  {_formatCurrencyPrice(purchaseOrderData.tax_total)}
                </td>
              </tr>
              <tr style={{ fontWeight: "bold" }}>
                <td style={{ textAlign: "right" }} colSpan={"4"}>
                  Total
                </td>
                <td style={{ textAlign: "right" }}>
                  {_formatCurrencyPrice(purchaseOrderData.net_total)}
                </td>
              </tr>
            </tfoot>
          </Table>
        </Card.Body>
      </Card>
    </Container>
  );
}
export default ViewPurchaseOrder;
