import { Container, Button, Row, Col } from "react-bootstrap";
import AppFooter from "../components/AppFooter";
export default function TermsConditions() {
  return (
    <>
      <div style={{ background: "rgb(54, 147, 61)", color: "white" }}>
        <Container className="py-3">
          <h2>
            <strong>Terms and Conditions</strong>
          </h2>
        </Container>
      </div>
      <Container className="my-5"></Container>
      <AppFooter />
    </>
  );
}
