import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import {
  Container,
  Card,
  Form,
  Button,
  Row,
  Col,
  Table,
} from "react-bootstrap";
import { fetchProductLists, fetchProduct } from "../../services/products";
import { createSellerProduct } from "../../services/sellerProducts";
import AppAlert from "../../components/AppAlert";
import { _swal } from "../../utils/helper";
import WarehouseSelect from "../../components/Input/WarehouseSelect";
import {
  BsCheckCircleFill,
  BsCheckSquareFill,
  BsCircleFill,
  BsPlusCircleFill,
} from "react-icons/bs";
import { BiSolidMinusCircle } from "react-icons/bi";
function CreateSellerProduct() {
  const navigate = useNavigate();
  const [productListData, setProductListData] = useState([]);
  const [productVariants, setProductVariants] = useState([]);
  const [selectedProductVariants, setSelectedProductVariants] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]);
  const [productAttributes, setProductAttributes] = useState([]);
  const [filteredProductVariants, setFilteredProductVariants] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState({});
  const _fetchProductLists = async () => {
    await fetchProductLists()
      .then((response) => {
        const { data } = response;
        setProductListData(data.data.products);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };
  const _fetchProduct = async (id) => {
    await fetchProduct(id)
      .then((response) => {
        const { data } = response;
        const _productVariants = [];
        data.data.product.product_variants.forEach((v) => {
          console.log(v);
          _productVariants.push({
            productId: data.data.product.id,
            productVariantId: v["id"],
            variantOptions: v["title"].split(":"),
            variantName: v["title"].replaceAll(":", " / "),
          });
        });
        setProductVariants(_productVariants);
        setProductAttributes(JSON.parse(data.data.product.product_options));
        setFilteredProductVariants(_productVariants);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  function filterVariants(array, criteria) {
    return array.filter((obj) => {
      return criteria.every((criteriaItem) => {
        const index = parseInt(criteriaItem.index);
        return (
          obj.variantOptions[index] &&
          criteriaItem.values.includes(obj.variantOptions[index])
        );
      });
    });
  }
  const filterOptions = (index, values) => {
    const _filteredOptions = { ...filteredOptions };
    _filteredOptions[index] = values;
    setFilteredOptions(_filteredOptions);
    const searchCriteria = [];
    Object.entries(_filteredOptions).forEach((fo) => {
      if (fo[1].length) {
        searchCriteria.push({
          index: fo[0],
          values: fo[1],
        });
      }
    });
    setFilteredProductVariants([
      ...filterVariants([...productVariants], searchCriteria),
    ]);
  };
  const parseAttributeOptions = (options) => {
    const _options = [];
    options.forEach((e) => {
      if (e)
        _options.push({
          value: e,
          label: e,
        });
    });
    return _options;
  };
  const updateSelectedProductVariants = (id, action) => {
    let _selectedProductVariants = [...selectedProductVariants];
    if (action === "+") {
      if (!_selectedProductVariants.includes()) {
        _selectedProductVariants.push(id);
      }
    } else {
      _selectedProductVariants = _selectedProductVariants.filter((e) => {
        return e !== id;
      });
    }
    console.log(
      "_selectedProductVariants",
      action,
      id,
      _selectedProductVariants
    );
    setSelectedProductVariants(_selectedProductVariants);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    const formData = {
      warehouse_id: e.target.warehouse_id.value,
      product_id: e.target.product_id.value,
      product_variants: selectedProductVariants,
    };
    createSellerProduct(formData)
      .then((response) => {
        if (response.data.status) {
          _swal(response.data.status, response.data.message);
          navigate("/seller/products");
        }
      })
      .catch((err) => {
        if (err.response.data.errors) {
          const _errorMessages = [];
          Object.entries(err.response.data.errors).forEach((e) => {
            _errorMessages.push(e[1]);
          });
          setErrorMessages([..._errorMessages]);
        }
        _swal(err.response.data.status, err.response.data.message);
      });
  };
  useEffect(() => {
    _fetchProductLists();
  }, []);
  return (
    <Container className="my-2">
      <Card>
        <Card.Header as="h5">Import Product</Card.Header>
        <Card.Body>
          <Form onSubmit={onSubmit}>
            {errorMessages.length ? <AppAlert errors={errorMessages} /> : null}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Warehouse Name
              </Form.Label>
              <Col sm="4">
                <WarehouseSelect />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Product Name
              </Form.Label>
              <Col sm="4">
                <Form.Select
                  name="product_id"
                  onChange={(e) => _fetchProduct(e.target.value)}
                >
                  <option value="">Select the Product</option>
                  {productListData.length !== 0
                    ? productListData.map((v) => {
                        return <option value={v["id"]}>{v["title"]}</option>;
                      })
                    : null}
                </Form.Select>
              </Col>
            </Form.Group>
            <Row>
              <Col md="4">
                {productAttributes.length !== 0 ? (
                  <>
                    <h5>Filter Variants</h5>
                    {productAttributes.map((pv, index) => {
                      return (
                        <Form.Group as={Row} className="mt-2">
                          <Form.Label>{pv["name"]}</Form.Label>
                          <Select
                            isMulti
                            name="categories"
                            options={
                              pv["options"].length
                                ? parseAttributeOptions(pv["options"])
                                : []
                            }
                            onChange={(e) => {
                              const _selectedOptions = [];
                              e.forEach((sv) => {
                                _selectedOptions.push(sv["value"]);
                              });
                              filterOptions(index, _selectedOptions);
                            }}
                          />
                        </Form.Group>
                      );
                    })}
                  </>
                ) : null}
              </Col>
              <Col md="8">
                {filteredProductVariants.length ? (
                  <div style={{ maxHeight: "500px", overflow: "auto" }}>
                    <Table className="mt-2" bordered responsive width={"100%"}>
                      <thead>
                        <tr>
                          <th>Product Variant</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredProductVariants.length
                          ? filteredProductVariants.map((pv) => {
                              return (
                                <tr>
                                  <td>
                                    {selectedProductVariants.includes(
                                      pv.productVariantId
                                    ) ? (
                                      <BsCheckCircleFill
                                        onClick={() =>
                                          updateSelectedProductVariants(
                                            pv.productVariantId,
                                            "-"
                                          )
                                        }
                                        style={{
                                          color: "green",
                                          cursor: "pointer",
                                        }}
                                      />
                                    ) : (
                                      <BsCircleFill
                                        onClick={() =>
                                          updateSelectedProductVariants(
                                            pv.productVariantId,
                                            "+"
                                          )
                                        }
                                        style={{
                                          color: "orange",
                                          cursor: "pointer",
                                        }}
                                      />
                                    )}
                                    <span> {pv.variantName}</span>
                                  </td>
                                </tr>
                              );
                            })
                          : null}
                      </tbody>
                    </Table>
                  </div>
                ) : null}
              </Col>
            </Row>

            <br />
            <Button className="mt-2" type="submit" variant="primary">
              Import
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default CreateSellerProduct;
