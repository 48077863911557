import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import {
  Container,
  Card,
  Form,
  Button,
  Table,
  Row,
  Col,
  Modal,
} from "react-bootstrap";
import {
  fetchSellerProducts,
  deleteSellerProduct,
  fetchSellerProductMake,
  createSellerProductMake,
  updateSellerProductMake,
} from "../../services/sellerProducts";
import AppAlert from "../../components/AppAlert";
import { _formatCurrencyPrice, _swal, _units } from "../../utils/helper";
import CountrySelect from "../../components/Input/CountrySelect";
import { fetchWarehouseLists } from "../../services/warehouses";
function SellerProductsList() {
  const [sellerProductsData, setSellerProductsData] = useState({});
  const [sellerProductMakeData, setSellerProductMakeData] = useState({
    id: null,
    title: null,
    sellerProductId: null,
  });
  const [makeStatusModalShow, setMakeModalShow] = useState(false);
  const searchSchema = {
    warehouse: null,
  };
  const [warehouseOptions, setWarehouseOptions] = useState([]);
  const [SellerProductSearchValues, setSellerProductSearchValues] =
    useState(searchSchema);
  const _fetchSellerProducts = async (queryParams = {}) => {
    await fetchSellerProducts(queryParams)
      .then((response) => {
        const { data } = response;
        setSellerProductsData(data.data.seller_products);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };
  const _deleteSellerProduct = async (id) => {
    const _confirm = window.confirm("Are you sure to delete?");
    if (_confirm) {
      await deleteSellerProduct(id)
        .then((response) => {
          const { data } = response;
          _swal(response.data.status, response.data.message);
          _fetchSellerProducts();
        })
        .catch((err) => {
          _swal(err.response.data.status, err.response.data.message);
          console.log(err.response.data);
        });
    }
  };
  const _fetchWarehouseLists = async () => {
    await fetchWarehouseLists()
      .then((response) => {
        const { data } = response;
        if (data.data.warehouses) {
          const _options = [];
          const _selectedOptions = [];
          data.data.warehouses.forEach((v) => {
            _options.push({
              value: v["id"],
              label: v["name"],
            });
          });
          setWarehouseOptions(_options);
        }
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };
  const resetSearch = () => {
    _fetchSellerProducts({
      page: 1,
      per_page: 100,
      sort_column: "id",
      sort_order: "DESC",
    });
  };
  const filterSellerProduct = (
    page = null,
    sort_column = null,
    sort_order = null
  ) => {
    const filteredKeys = {};
    Object.entries(SellerProductSearchValues).filter((v) => {
      if (v[1] !== null) {
        filteredKeys[v[0]] = v[1];
      }
    });
    _fetchSellerProducts({
      ...filteredKeys,
      page: page ? page : 1,
      per_page: 100,
      sort_column: sort_column ? sort_column : "id",
      sort_order: sort_order ? sort_order : "DESC",
    });
  };
  useEffect(() => {
    _fetchSellerProducts({
      page: 1,
      per_page: 100,
      sort_column: "id",
      sort_order: "DESC",
    });
    _fetchWarehouseLists();
  }, []);
  return (
    <Container className="my-2">
      <Card>
        <Card.Header as="h5">
          My Products{" "}
          <Button
            variant="primary"
            as={Link}
            to={"/seller/products/import"}
            size="sm"
          >
            Import
          </Button>
        </Card.Header>
        <Card.Body>
          <Form>
            <Row>
              <Col md="3">
                <Form.Group className="mb-3">
                  <Form.Label>Supplier Location</Form.Label>
                  <Select
                    name={"warehouse_id"}
                    options={warehouseOptions}
                    onChange={(e) => {
                      setSellerProductSearchValues({
                        ...SellerProductSearchValues,
                        warehouse: e.value,
                      });
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Button
              onClick={() => filterSellerProduct()}
              variant="primary"
              className="mr-2"
            >
              Filter
            </Button>
            <Button
              onClick={(e) => {
                setSellerProductSearchValues({ ...searchSchema });
                resetSearch();
              }}
              variant="default"
            >
              Reset
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <Table bordered width={"100%"} className="mt-2">
        <thead>
          <tr>
            <th width="15%">Supplier Location</th>
            <th width="15%">Product Description</th>
            <th width="70%">Product Make</th>
          </tr>
        </thead>
        <tbody>
          {sellerProductsData.length ? (
            sellerProductsData.map((e) => {
              console.log(e);
              return (
                <tr>
                  <td>{e.warehouses ? e.warehouses.name : null}</td>
                  <td>
                    {e.products && e.products.title} (
                    {e.product_variants && e.product_variants.formatted_title})
                    <br />
                    <Button
                      variant="warning"
                      size="sm"
                      onClick={() => _deleteSellerProduct(e.id)}
                    >
                      Delete
                    </Button>
                    <Button
                      variant="info"
                      size="sm"
                      onClick={() => {
                        setSellerProductMakeData({
                          title: e.product_variants.formatted_title,
                          sellerProductId: e.id,
                        });
                        setMakeModalShow(true);
                      }}
                    >
                      Add Make
                    </Button>
                  </td>
                  <td className="p-0 m-0">
                    {e.seller_product_makes.length ? (
                      <Table width={"100%"} bordered responsive>
                        <thead>
                          <tr>
                            <th>Make</th>
                            <th>Country of Origin</th>
                            <th>Inspection</th>
                            <th>Payment</th>
                            <th>Delivery Temrs</th>
                            <th style={{ textAlign: "right" }}>VAT (%)</th>
                            <th className="text-center">Stock</th>
                            <th style={{ textAlign: "right" }}>Unit Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          {e.seller_product_makes.map((b) => {
                            return (
                              <tr>
                                <td>
                                  {b.make_name}
                                  <br />
                                  <Button
                                    variant="secondary"
                                    size="sm"
                                    onClick={() => {
                                      setSellerProductMakeData({
                                        ...sellerProductMakeData,
                                        id: b.id,
                                        title: e.product_variants.title,
                                      });
                                      setMakeModalShow(true);
                                    }}
                                  >
                                    Edit
                                  </Button>
                                </td>
                                <td>{b.country.name}</td>
                                <td>{b.inspection}</td>
                                <td>{b.payment}</td>
                                <td>{b.delivery_terms}</td>
                                <td style={{ textAlign: "right" }}>
                                  {b.vat} %
                                </td>
                                <td className="text-center">
                                  {b.available_stock}{" "}
                                  {_units(e.products.unit_measurement)}
                                </td>
                                <td style={{ textAlign: "right" }}>
                                  {_formatCurrencyPrice(b.price)}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    ) : (
                      "No Makes"
                    )}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={"5"}>Seller Products not found</td>
            </tr>
          )}
        </tbody>
      </Table>
      {sellerProductMakeData && makeStatusModalShow ? (
        <CreateMakeUpdateModal
          sellerProductMakeData={sellerProductMakeData}
          onCloseMakeModal={setMakeModalShow}
        />
      ) : null}
      {sellerProductMakeData &&
      sellerProductMakeData.id &&
      makeStatusModalShow ? (
        <EditMakeUpdateModal
          id={sellerProductMakeData.id}
          title={sellerProductMakeData.title}
          onCloseMakeModal={setMakeModalShow}
        />
      ) : null}
    </Container>
  );
}

function CreateMakeUpdateModal(props) {
  const { sellerProductMakeData, onCloseMakeModal } = props;
  const [errorMessages, setErrorMessages] = useState([]);
  const [show, setShow] = useState(false);
  const onSubmit = (e) => {
    e.preventDefault();
    const formData = {
      sellerProductId: sellerProductMakeData.sellerProductId,
      makeName: e.target.make_name.value,
      countryOfOrigin: e.target.country_of_origin.value,
      inspection: e.target.inspection.value,
      payment: e.target.payment.value,
      delivery_terms: e.target.delivery_terms.value,
      packaging_cost: 0,
      inspection_cost: 0,
      delivery_cost: 0,
      customs_cost: 0,
      vat: e.target.vat.value,
      availableStock: e.target.available_stock.value,
      price: e.target.price.value,
    };
    createSellerProductMake(formData)
      .then((response) => {
        if (response.data.status) {
          _swal(response.data.status, response.data.message);
          onCloseMakeModal(false);
          window.location.href = "/seller/products";
        }
      })
      .catch((err) => {
        if (err.response.data.errors) {
          const _errorMessages = [];
          Object.entries(err.response.data.errors).forEach((e) => {
            _errorMessages.push(e[1]);
          });
          setErrorMessages([..._errorMessages]);
        }
        _swal(err.response.data.status, err.response.data.message);
      });
  };

  useEffect(() => {
    if (sellerProductMakeData.title) {
      setShow(true);
    }
  }, []);
  return (
    <>
      <Modal show={show} onHide={() => onCloseMakeModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title as="h5">Add Make</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={onSubmit}>
            {errorMessages.length ? <AppAlert errors={errorMessages} /> : null}
            <Form.Group className="mb-3">
              <Form.Label>Product</Form.Label>
              <Form.Control
                type="text"
                name="product"
                readOnly
                value={sellerProductMakeData.title}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Make Name</Form.Label>
              <Form.Control type="text" name="make_name" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Country of Origin</Form.Label>
              <CountrySelect
                inputName="country_of_origin"
                isMultiple={false}
                defaultOptions={[]}
                onChangeCountryOption={null}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Inspection</Form.Label>
              <Form.Select name="inspection">
                <option value="Inspection certificate 2.1">
                  Inspection certificate 2.1
                </option>
                <option value="Inspection certificate 2.2">
                  Inspection certificate 2.2
                </option>
                <option value="Inspection certificate 3.1">
                  Inspection certificate 3.1
                </option>
                <option value="Inspection certificate 3.2">
                  Inspection certificate 3.2
                </option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Payment</Form.Label>
              <Form.Select name="payment">
                <option value="Mutually agreed">Mutually agreed</option>
                <option value="Against delivery">Against delivery</option>
                <option value="15 days PDC">15 days PDC</option>
                <option value="30 days PDC">30 days PDC</option>
                <option value="45 Days PDC">45 Days PDC</option>
                <option value="60 Days PDC">60 Days PDC</option>
                <option value="90 Days PDC">90 Days PDC</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Delivery Terms</Form.Label>
              <Form.Select name="delivery_terms">
                <option value="With in 2-4 working days in same country">
                  With in 2-4 working days in same country
                </option>
                <option value="With in 7 working days in the same country">
                  With in 7 working days in the same country
                </option>
                <option value="With in 15 working days in the same country ">
                  With in 15 working days in the same country{" "}
                </option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>VAT Cost (%)</Form.Label>
              <Form.Control type="number" min="0" max="30" name="vat" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Available Stock</Form.Label>
              <Form.Control type="text" name="available_stock" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Price ($)</Form.Label>
              <Form.Control type="text" name="price" />
            </Form.Group>
            <Button type="submit" variant="primary">
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

function EditMakeUpdateModal(props) {
  const { id, title, onCloseMakeModal } = props;
  const [sellerProductMakeData, setSellerProductMakeData] = useState({});
  const [errorMessages, setErrorMessages] = useState([]);
  const [show, setShow] = useState(false);
  const _fetchSellerProductMake = async (id) => {
    await fetchSellerProductMake(id)
      .then((response) => {
        const { data } = response;
        setSellerProductMakeData(data.data.seller_product_make);
      })
      .catch((err) => {
        _swal(err.response.data.status, err.response.data.message);
        console.log(err.response.data);
      });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    const formData = {
      makeName: e.target.make_name.value,
      countryOfOrigin: e.target.country_of_origin.value,
      inspection: e.target.inspection.value,
      payment: e.target.payment.value,
      delivery_terms: e.target.delivery_terms.value,
      packaging_cost: 0,
      inspection_cost: 0,
      delivery_cost: 0,
      customs_cost: 0,
      vat: e.target.vat.value,
      availableStock: e.target.available_stock.value,
      price: e.target.price.value,
    };
    updateSellerProductMake(id, formData)
      .then((response) => {
        if (response.data.status) {
          _swal(response.data.status, response.data.message);
          onCloseMakeModal(false);
          window.location.href = "/seller/products";
        }
      })
      .catch((err) => {
        if (err.response.data.errors) {
          const _errorMessages = [];
          Object.entries(err.response.data.errors).forEach((e) => {
            _errorMessages.push(e[1]);
          });
          setErrorMessages([..._errorMessages]);
        }
        _swal(err.response.data.status, err.response.data.message);
      });
  };

  useEffect(() => {
    if (id) {
      _fetchSellerProductMake(id);
      setShow(true);
    }
  }, [id]);
  return (
    <>
      <Modal show={show} onHide={() => onCloseMakeModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title as="h5">Edit Make</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={onSubmit}>
            {errorMessages.length ? <AppAlert errors={errorMessages} /> : null}
            <Form.Group className="mb-3">
              <Form.Label>Product</Form.Label>
              <Form.Control type="text" name="product" readOnly value={title} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Make Name</Form.Label>
              <Form.Control
                type="text"
                name="make_name"
                defaultValue={sellerProductMakeData.make_name}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Country of Origin</Form.Label>
              <CountrySelect
                inputName="country_of_origin"
                isMultiple={false}
                defaultOptions={sellerProductMakeData.country_of_origin}
                onChangeCountryOption={null}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Inspection</Form.Label>
              <Form.Select
                name="inspection"
                defaultValue={sellerProductMakeData.inspection}
              >
                <option value="Inspection certificate 2.1">
                  Inspection certificate 2.1
                </option>
                <option value="Inspection certificate 2.2">
                  Inspection certificate 2.2
                </option>
                <option value="Inspection certificate 3.1">
                  Inspection certificate 3.1
                </option>
                <option value="Inspection certificate 3.2">
                  Inspection certificate 3.2
                </option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Payment</Form.Label>
              <Form.Select
                name="payment"
                defaultValue={sellerProductMakeData.payment}
              >
                <option value="Mutually agreed">Mutually agreed</option>
                <option value="Against delivery">Against delivery</option>
                <option value="15 days PDC">15 days PDC</option>
                <option value="30 days PDC">30 days PDC</option>
                <option value="45 Days PDC">45 Days PDC</option>
                <option value="60 Days PDC">60 Days PDC</option>
                <option value="90 Days PDC">90 Days PDC</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Delivery Terms</Form.Label>
              <Form.Select
                name="delivery_terms"
                defaultValue={sellerProductMakeData.delivery_terms}
              >
                <option value="With in 2-4 working days in same country">
                  With in 2-4 working days in same country
                </option>
                <option value="With in 7 working days in the same country">
                  With in 7 working days in the same country
                </option>
                <option value="With in 15 working days in the same country ">
                  With in 15 working days in the same country{" "}
                </option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>VAT Cost (%)</Form.Label>
              <Form.Control
                type="number"
                name="vat"
                min="0"
                max="30"
                defaultValue={sellerProductMakeData.vat}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Available Stock</Form.Label>
              <Form.Control
                type="text"
                name="available_stock"
                defaultValue={sellerProductMakeData.available_stock}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Price ($)</Form.Label>
              <Form.Control
                type="text"
                name="price"
                defaultValue={sellerProductMakeData.price}
              />
            </Form.Group>
            <Button type="submit" variant="primary">
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SellerProductsList;
