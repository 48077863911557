import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Card, Form, Button, Row, Col } from "react-bootstrap";
import { createProductProposal } from "../../services/productProposals";
import AppAlert from "../../components/AppAlert";
import { _swal } from "../../utils/helper";
function CreateProductProposal() {
  const navigate = useNavigate();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]);
  const handleFileChange = (e) => {
    setSelectedFiles([...selectedFiles, ...e.target.files]);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("title", e.target.title.value);
    formData.append("description", e.target.description.value);
    selectedFiles.forEach((file) => {
      formData.append("documents", file);
    });
    createProductProposal(formData)
      .then((response) => {
        if (response.data.status) {
          _swal(response.data.status, response.data.message);
          navigate("/seller/product_proposals");
        }
      })
      .catch((err) => {
        if (err.response.data.errors) {
          const _errorMessages = [];
          Object.entries(err.response.data.errors).forEach((e) => {
            _errorMessages.push(e[1]);
          });
          setErrorMessages([..._errorMessages]);
        }
        _swal(err.response.data.status, err.response.data.message);
      });
  };
  return (
    <Container className="my-2">
      <Card>
        <Card.Header as="h5">Create Product Proposal</Card.Header>
        <Card.Body>
          <Form onSubmit={onSubmit}>
            {errorMessages.length ? <AppAlert errors={errorMessages} /> : null}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Title
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="text"
                  name="title"
                  placeholder="Title"
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Description
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  as="textarea"
                  name="description"
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Data Sheets
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  name="documents"
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={handleFileChange}
                />
              </Col>
            </Form.Group>
            <Button type="submit" variant="primary">
              Submit
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default CreateProductProposal;
