import axios from "axios";
import * as JWT from "jwt-decode";
import { v4 as uuidv4 } from "uuid";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)
export const _uuid = () => uuidv4();
export const _units = (unit = null) => {
    const units = {
        "M": "Meter",
        "Ft": "Feet",
        "Nos": "Number",
        "KG": "Kilo Grams",
        "PKT": "Packets",
        "D": "Drums",
        "L": "Liters",
        "ML": "Milli Litres",
        "KW": "Kilo Watts",
        "PCS": "Pieces",
        "A": "Amphere",
        "GMS": "Grams",
    };
    if (unit) {
        if (units.hasOwnProperty(unit)) {
            return units[unit];
        }
        return null;
    }
}
export const _swal = (icon, title) => MySwal.fire({
    title: title,
    icon: icon === 1 ? "success" : "error",
    showConfirmButton: false,
    timer: 2000
})
export const _formatCurrencyPrice = (price) => {
    return "$" + (price ? parseFloat(price).toFixed(2) : "0.00");
};
export const getToken = () => {
    if (localStorage.getItem("token") !== undefined && localStorage.getItem("token") !== "undefined" && localStorage.getItem("token"))
        return localStorage.getItem("token")
    return false
};
export const decodeJWT = () => {
    if (localStorage.getItem("token") !== undefined && localStorage.getItem("token") !== "undefined" && localStorage.getItem("token"))
        return JWT.jwtDecode(localStorage.getItem("token"))
    return false
};

export const cartesianProduct = (matrix) => {
    const cartesianRecursive = (current, remaining) => {
        if (remaining.length === 0) {
            result.push(current);
            return;
        }
        for (let i = 0; i < remaining[0].length; i++) {
            cartesianRecursive(current.concat(remaining[0][i]), remaining.slice(1));
        }
    }
    const result = [];
    cartesianRecursive([], matrix);
    return result;
}

const axiosInstance = axios.create();
axiosInstance.defaults.baseURL = "https://eprocureglobal.com/api/";
// axiosInstance.defaults.baseURL = "http://localhost:5000/";
axiosInstance.defaults.headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
};
axiosInstance.interceptors.request.use((config) => {
    const token = getToken();
    if (token) {
        config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
});
axiosInstance.interceptors.request.use(
    (response) => response,
    (error) => {
        if (error.status === 401) {
            alert("test");
        }
        return Promise.reject(error);
    }
);
export const _APIHandler = {
    instance: axiosInstance,
    get: axiosInstance.get,
    post: axiosInstance.post,
    put: axiosInstance.put,
    delete: axiosInstance.delete,
};