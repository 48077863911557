import { useNavigate, useParams } from "react-router-dom";
import { verifyEmail } from "../../services/auth";
import { useEffect } from "react";
import { _swal } from "../../utils/helper";
function VerifyEmail() {
  const { token } = useParams();
  const navigate = useNavigate();
  const _verifyEmail = () => {
    verifyEmail(token)
      .then((response) => {
        if (response.data.status) {
          _swal(response.data.status, response.data.message);
          navigate("/auth/signin");
        }
      })
      .catch((err) => {
        _swal(err.response.data.status, err.response.data.message);
      });
  };
  useEffect(() => {
    _verifyEmail();
  }, []);
  return <></>;
}

export default VerifyEmail;
