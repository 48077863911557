import { useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import { resetPassword } from "../../services/auth";
import { _swal } from "../../utils/helper";
function ResetPassword() {
  const { token } = useParams();
  const navigate = useNavigate();
  const onSubmit = (e) => {
    e.preventDefault();
    const formData = {
      password: e.target.password.value,
    };
    resetPassword(token, formData)
      .then((response) => {
        if (response.data.status) {
          _swal(response.data.status, response.data.message);
          navigate("/auth/signin");
        }
        else{
          _swal(response.data.status, response.data.message);
        }
      })
      .catch((err) => {
        _swal(err.response.data.status, err.response.data.message);
      });
  };
  return (
    <Container className="my-5">
      <Row>
        <Col md="4"></Col>
        <Col md="4">
          <Card>
            <Card.Header as="h5">Reset Password</Card.Header>
            <Card.Body>
              <Form onSubmit={onSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>Enter your New Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    placeholder="Enter password"
                  />
                </Form.Group>
                <div className="d-grid gap-2">
                  <Button type="submit" variant="primary">
                    Reset Password
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
        <Col md="4"></Col>
      </Row>
    </Container>
  );
}

export default ResetPassword;
