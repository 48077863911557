import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Card, Form, Button, Row, Col } from "react-bootstrap";
import { fetchWarehouse, updateWarehouse } from "../../services/warehouses";
import AppAlert from "../../components/AppAlert";
import { _swal } from "../../utils/helper";
import CountrySelect from "../../components/Input/CountrySelect";
function EditWarehouse() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [errorMessages, setErrorMessages] = useState([]);
  const [WarehouseData, setWarehouseData] = useState({});
  const _fetchWarehouse = async (id) => {
    await fetchWarehouse(id)
      .then((response) => {
        const { data } = response;
        setWarehouseData(data.data.warehouse);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    const formData = {
      name: e.target.name.value,
      address_1: e.target.address_1.value,
      address_2: e.target.address_2.value,
      city: e.target.city.value,
      country_id: e.target.country_id.value,
      zip_code: e.target.zip_code.value,
      phone_number: e.target.phone_number.value,
      status: e.target.status.value,
    };
    updateWarehouse(id, formData)
      .then((response) => {
        if (response.data.status) {
          _swal(response.data.status, response.data.message);
          navigate("/seller/warehouses");
        }
      })
      .catch((err) => {
        if (err.response.data.errors) {
          const _errorMessages = [];
          Object.entries(err.response.data.errors).forEach((e) => {
            _errorMessages.push(e[1]);
          });
          setErrorMessages([..._errorMessages]);
        }
        _swal(err.response.data.status, err.response.data.message);
      });
  };
  useEffect(() => {
    _fetchWarehouse(id);
  }, []);
  return (
    <Container className="my-2">
      <Card>
        <Card.Header as="h5">Edit Supplier Location</Card.Header>
        <Card.Body>
          <Form onSubmit={onSubmit}>
            {errorMessages.length ? <AppAlert errors={errorMessages} /> : null}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Supplier Location Name
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Supplier Location Name"
                  defaultValue={WarehouseData ? WarehouseData.name : null}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Address 1
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="text"
                  name="address_1"
                  placeholder="Address 1"
                  defaultValue={WarehouseData ? WarehouseData.address_1 : null}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Address 2
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="text"
                  name="address_2"
                  placeholder="Address 2"
                  defaultValue={WarehouseData ? WarehouseData.address_2 : null}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                City
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="text"
                  name="city"
                  placeholder="City"
                  defaultValue={WarehouseData ? WarehouseData.city : null}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Country
              </Form.Label>
              <Col sm="4">
                {WarehouseData && WarehouseData.country_id ? (
                  <CountrySelect selectedValues={[WarehouseData.country_id]} />
                ) : null}
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Zip Code
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="text"
                  name="zip_code"
                  placeholder="Zip Code"
                  defaultValue={WarehouseData ? WarehouseData.zip_code : null}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Phone Number
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="text"
                  name="phone_number"
                  placeholder="Phone Number"
                  defaultValue={
                    WarehouseData ? WarehouseData.phone_number : null
                  }
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Status
              </Form.Label>
              <Col sm="4">
                <Form.Select
                  name="status"
                  defaultValue={WarehouseData ? WarehouseData.status : null}
                >
                  <option>Active</option>
                  <option>In-Active</option>
                </Form.Select>
              </Col>
            </Form.Group>
            <Button type="submit" variant="primary">
              Submit
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default EditWarehouse;
