import { useState } from "react";
import Alert from "react-bootstrap/Alert";

function AppAlert({ errors }) {
  const [show, setShow] = useState(true);

  if (show) {
    return (
      <Alert variant="danger" onClose={() => setShow(false)}>
        <Alert.Heading as="h6">Oops!, Invalid Request</Alert.Heading>
        {errors.length ? (
          <ul>
            {errors.map((e) => {
              return <li>{e}</li>;
            })}
          </ul>
        ) : null}
      </Alert>
    );
  }
}

export default AppAlert;
