import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Card, Form, Button, Row, Col } from "react-bootstrap";
import { fetchUser, updateUser } from "../../services/users";
import AppAlert from "../../components/AppAlert";
import { _swal } from "../../utils/helper";
import CountrySelect from "../../components/Input/CountrySelect";
function EditUser() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [errorMessages, setErrorMessages] = useState([]);
  const [UserData, setUserData] = useState({});
  const _fetchUser = async (id) => {
    await fetchUser(id)
      .then((response) => {
        const { data } = response;
        setUserData(data.data.user);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    const formData = {
      first_name: e.target.first_name.value,
      last_name: e.target.last_name.value,
      company_name: e.target.company_name.value,
      address_1: e.target.address_1.value,
      address_2: e.target.address_2.value,
      city: e.target.city.value,
      country_id: e.target.country_id.value,
      zip_code: e.target.zip_code.value,
      phone_number: e.target.phone_number.value,
    };
    updateUser(id, formData)
      .then((response) => {
        if (response.data.status) {
          _swal(response.data.status, response.data.message);
          navigate("/admin/users");
        }
      })
      .catch((err) => {
        if (err.response.data.errors) {
          const _errorMessages = [];
          Object.entries(err.response.data.errors).forEach((e) => {
            _errorMessages.push(e[1]);
          });
          setErrorMessages([..._errorMessages]);
        }
        _swal(err.response.data.status, err.response.data.message);
      });
  };
  useEffect(() => {
    _fetchUser(id);
  }, []);
  return (
    <Container className="my-2">
      <Card>
        <Card.Header as="h5">Edit User</Card.Header>
        <Card.Body>
          <Form onSubmit={onSubmit}>
            {errorMessages.length ? <AppAlert errors={errorMessages} /> : null}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                First Name
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="text"
                  name="first_name"
                  placeholder="First Name"
                  defaultValue={UserData ? UserData.first_name : null}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Last Name
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="text"
                  name="last_name"
                  placeholder="Last Name"
                  defaultValue={UserData ? UserData.last_name : null}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Company Name
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="text"
                  name="company_name"
                  placeholder="Company Name"
                  defaultValue={UserData ? UserData.company_name : null}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Address 1
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="text"
                  name="address_1"
                  placeholder="Address 1"
                  defaultValue={UserData ? UserData.address_1 : null}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Address 2
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="text"
                  name="address_2"
                  placeholder="Address 2"
                  defaultValue={UserData ? UserData.address_2 : null}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                City
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="text"
                  name="city"
                  placeholder="City"
                  defaultValue={UserData ? UserData.city : null}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Country
              </Form.Label>
              <Col sm="4">
                {UserData && UserData.country_id ? (
                  <CountrySelect selectedValues={[UserData.country_id]} />
                ) : null}
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Zip Code
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="text"
                  name="zip_code"
                  placeholder="Zip Code"
                  defaultValue={UserData ? UserData.zip_code : null}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Phone Number
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="text"
                  name="phone_number"
                  placeholder="Phone Number"
                  defaultValue={UserData ? UserData.phone_number : null}
                />
              </Col>
            </Form.Group>
            <Button type="submit" variant="primary">
              Submit
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default EditUser;
